header {
    position: absolute;
    z-index: 9;
    width: 100%;
}

.hemburgur {
    width: 35px;
    height: 30px;
    transform: rotate(0deg);
    position: relative;
    float: right;
    z-index: 1;
    right: 0;
    background: transparent;
    border: none;
    outline: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-left: 30px;
}

.hemburgur span:nth-child(1) {
    top: 10px;
}

.hemburgur span:nth-child(2) {
    top: 22px;
    width: 100%;
}

.hemburgur span:nth-child(3) {
    top: 34px;
    width: 100%;
}

.hemburgur span {
    display: block;
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #fff;
    transition: .5s ease-in-out;
    float: left;
    position: absolute;
}

.hemburgur.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 15px;
    left: 5px;
    width: 80%;
}

.hemburgur.active span:nth-child(2) {
    opacity: 0;
}

.hemburgur.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 15px;
    left: 5px;
    width: 80%;
}

.logo {
    max-width: 120px;
}

.navbar ul,
.topcta,
.header.fixed,
.logo a {
    width: 100%;
    float: left;
}


.navbar ul:last-child {
    gap: 10px;
}

.accImg {
    height: 25px;
    width: auto;
    padding-right: 10px;
}

.navbar {
    padding: 0;
}

.menu,
.navbar ul,
.headerstn,
.topcta,
.topcta ul,
.slideimg,
.jujhar,
.logo a {
    display: flex;
    align-items: center;
}

.navbar ul li span {
    color: #d51d3f;
    display: flex;
    align-items: baseline;
    /* padding-left: 5%;
    padding-right: 5%; */
}

.logo a {
    justify-content: start;
}

.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.navbar ul li {
    font-size: 15px;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    padding: 0 15px;
}

.navbar ul li a {
    position: relative;
    z-index: 9;
    display: inline-block;
    font-family: "Franklin Gothic Book";
    cursor: pointer;
}

.headerstn {
    justify-content: space-between;
    border-bottom: solid 1px rgb(255 255 255 / 30%);
    padding: 25px 0;
}

.menu {
    flex-wrap: wrap;
}

.topcta {
    margin-bottom: 10px;
}

.fixed .headerstn {
    padding: 5px 0;
}

.topcta ul li {
    margin: 0 10px;
    background: #fff;
    border-radius: 25px;
    overflow: hidden;
}

.topcta ul li a {
    padding: 0px 40px;
    line-height: 44px;
    font-size: 15px;
    float: left;
    text-transform: capitalize;
}

.fixed .menu.active .hemburgermenus .submenu,
.fixed .navbar ul li:hover .dropdown .submenu {
    height: 84%;
}

.topcta ul li:last-child {
    margin-right: 0;
}

.jujhar {
    width: 176px;
    height: 60px;
    border-radius: 27px 0px 0px 27px;
    background: #fff;
    position: absolute;
    right: -5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed {
    animation: smoothScroll 1s forwards;
    left: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .12);
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.header.fixed {
    background: #fff;
    width: 100%;
    padding: 0;
    z-index: 9;
}

.fixed .navbar ul li a,
.navbar ul li a:hover,
.navbar .sitemapcol h4 a:hover,
.fixed .navbar .sitemapcol h4 a:hover {
    color: #000;
}

.fixed .topcta ul li {
    background: #ea354c;
}

.fixed .topcta ul li a,
.navbar ul li a,
.navbar ul li {
    color: #fff;
}

.fixed .hemburgur span {
    background: #000;
}

.logo img:last-child {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: ease all 0.5s;
}

.fixed .logo {
    max-width: 100px;
}

.fixed .logo img:last-child {
    opacity: 1;
    width: 95%;
}

.fixed .jujhar {
    background: #ebebeb;
}

.fixed .logo img:first-child {
    opacity: 0;
}

.jujhar,
.topcta ul li,
.navbar ul li a,
.dropdown,
.logo,
.headerstn {}

.buttnprop:hover span {
    top: 0;
    left: 0;
}

.buttnprop:hover span {
    background: #292a2b;
    color: #d6223a;
}

.buttnprop:hover {
    border-color: #292a2b;
}

.chooseplan .title h4 {
    color: #fff;
}


.chooseplan .buttnprop:hover span {
    background: #000;
    color: #fff;
}

.chooseplan .buttnprop:hover {
    border-color: #000;
}

/* .chooseplan .buttnprop {border-color: #fff;} */
.topcta ul li:before {
    content: '';
    background: #292a2b;
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    transition: ease all 0.5s;
    z-index: 1;
}

.topcta ul li:hover:before {
    height: 100%;
}

.topcta ul li:hover a {
    color: #fff;
}

.topcta ul li a,
.headerstn {
    position: relative;
    z-index: 2;
}

.dropdown {
    align-items: flex-end;
    display: flex;
    height: 0;
    left: -5%;
    opacity: 0;
    position: fixed;
    top: 10%;
    transition: all .5s ease;
    visibility: hidden;
    width: 110%;
    height: 55vh;
    display: none;
}

.navbar ul li:hover .dropdown,
.menu.active .hemburgermenus .dropdown {
    opacity: 1;
    top: 0;
    visibility: visible;
    display: block;
}

.submenu {
    display: none;
    float: left;
    opacity: 0;
    visibility: hidden;
    width: 100%;
}

.menu.active .hemburgermenus .submenu,
.navbar ul li:hover .dropdown .submenu {
    display: block;
    height: 100%;
    opacity: 1;
    visibility: visible;
}

.menubox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto;
    padding-top: 20vh;
    width: 62%;
    float: right;
    margin-right: 5%;
}

.dropdown li {
    margin: 10px 0;
    font-size: 15px;
    line-height: 24px;
}

.sitemapcol {
    width: 20%;
    margin: 25px 0;
}

.navbar .sitemapcol ul {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0;
}

.navbar .sitemapcol ul li {
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border-radius: 0;
    text-align: left;
}

.sitemapcol h4 {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
}

.navbar ul li a.active:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    transition: ease all 0.5s;
}

.navbar ul li .dropdown .sitemapcol {
    background: transparent;
    border-radius: 0;
    text-align: left;
    width: auto;
    margin: 0;
}

.hemburgermenus {
    width: 300px;
    float: left;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 9;
    padding: 25px 50px;
    padding-bottom: 100px;
    border-radius: 0 0 0 100px;
    opacity: 0;
    display: none;
}

.menu.active .hemburgermenus {
    opacity: 1;
    display: block;
}

.hemburgermenus .dropdown {
    width: auto;
    position: static;
    transform: scale(1, 0);
}

.menu.active .hemburgermenus .dropdown {
    transform: scale(1, 1);
}

.menu.active .hemburgermenus .submenu,
.menu.active .hemburgermenus .dropdown {
    height: auto;
}

.hemburgermenus .dropdown .sitemapcol ul li {
    border-bottom: solid 1px rgb(241 241 241 / 25%);
    padding: 5px 0;
    margin: 5px 0;
}

.hemburgermenus .dropdown .sitemapcol ul li:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.dropdown li a:hover {
    color: #000 !important;
}

.hemburgermenus .dropdown .submenu {
    background: transparent;
}

svg {
    width: 100%;
    height: auto;
}

.sliderrow {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.btn-11:hover {
    border-color: #e6394b;
}

.btn-11:hover a span,
.dropdown li a {
    color: #fff;
}

.bundlebox .btn-11 {
    bottom: 0;
    justify-content: center;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: auto;
}

#blacktext .headerstn {
    border-color: #ebebeb;
}

.hover path.fil2:last-child {
    fill: #fff;
}

.hover .navbar ul li:hover a,
#blacktext .hover .fixed .topcta ul li:hover a,
#blacktext .hover .navbar ul li:hover a {
    color: #fff;
}

.hover .navbar ul li a,
#blacktext .hover .fixed .topcta ul li a,
#blacktext .hover .navbar ul li a {
    color: #c3c3c3;
}

li.Srch_btn {
    border: 1px solid #ccc;
    border-radius: 35px;
}

.Srch_btn input[type="text"] {
    background: transparent;
    border: 0;
    color: #fff;
    width: 140px;
    font-size: 15px;
    text-align: left;
    letter-spacing: 0.5px;
}

.Srch_btn form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

li.Srch_btn button img {
    width: 30px;
    height: 30px;
    filter: brightness(100%) invert(100%);
}

.Srch_btn button {
    all: unset;
}

li.Srch_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

li.Srch_btn button {
    height: 230%;
    width: 100%;
    justify-content: center;
    align-items: center !important;
    display: flex;
    /* background: #fff; */
}

.Srch_btn input[type="text"]::placeholder {
    color: #fff;
    font-weight: 200;
}

.header.fixed .Srch_btn input[type="text"] {
    color: #000 !important;
}


#blacktext .Srch_btn button img,
.header_white .Srch_btn button img,
.header.fixed .Srch_btn button img {
    filter: none;
}
#blacktext .Srch_btn ,
.header_white .Srch_btn ,  
#blacktext .Srch_btn ,
.header_white .Srch_btn 
.header.fixed .Srch_btn {
    border-color: #777;

}
#blacktext .Srch_btn input[type="text"]::placeholder,  
.header_white .Srch_btn input[type="text"]::placeholder,  
#blacktext .Srch_btn input[type="text"],
.header_white .Srch_btn input[type="text"],
.header.fixed .Srch_btn input[type="text"]::placeholder {
    color: #000;
}