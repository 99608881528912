@media all and (max-width:1740px) {

    .channelpack h4,
    .channelpack ul li strong {
        font-size: 24px;
        line-height: 30px;
    }

    .androidfeature ul li h4,
    .channelpack ul li {
        font-size: 19px;
        line-height: 26px;
    }

    .channelpack {
        width: 30%;
    }

    .banner:before {
        width: 220px;
        height: 220px;
    }

    .logo {
        margin-right: 1%;
        width: 120px;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
    .navbar ul li {
        padding: 0 10px;
    }

    .jujhar {
        width: 125px;
        height: 50px;
    }

    .appleft {
        padding: 50px;
    }

    .dropdown:before {
        left: 8.5%;
        width: 9.6rem;
        top: -5px;
    }

    .navbar ul:last-child {
        gap: 7px;
    }

    .dropdown .logo {
        left: 6%;
    }


    .androidbox ul li p {
        font-size: 21px;
        line-height: 26px;
    }

    .setupbox {
        width: 30%;
    }

    .headerstn {
        padding: 15px 0;
    }

    .slidecont {
        margin: 0;
    }

    .contactbox li p strong {
        font-size: 28px;
    }
}

@media all and (max-width:1600px) {
    .squarecont li strong {
        font-size: 60px;
        line-height: 60px;
    }

    .freepacks li span {
        font-size: 9px;
        letter-spacing: 2px;
    }

    .freepacks li strong {
        font-size: 80px;
        line-height: 50px;
    }

    .freepacks li strong small {
        font-size: 11px;
    }

    .banner:before {
        width: 200px;
        height: 200px;
    }

    .fixed .dropdown .logo {
        top: 20%;
        left: 0;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
    .navbar ul li {
        line-height: 30px;
        font-size: 14px;
        letter-spacing: 2px;
    }

    .slideimg img {
        width: 95%;
    }

    .apprgt img {
        width: 70%;
        float: right;
    }

    .plansitem {
        width: 94%;
    }

    .featurebox h4,
    .featureInfo {
        font-size: 21px;
    }

    .sitemapcol h4 {
        font-size: 13px;
    }

    .dropdown .logo {
        left: 7%;
    }

    .circle02 {
        width: 280px;
        height: 280px;
        top: -30%;
    }

    .circle02 h2 {
        font-size: 32px;
        line-height: 32px;
    }


    .circlimg img {
        width: 75%;
        float: right;
    }

    .pricestn li,
    .ptag h5 {
        font-size: 22px;
        line-height: 24px;
    }

    .pricestn h3 {
        font-size: 17px;
    }

    .pricestn h3 strong,
    .pricestn li strong,
    .listpoint ul li strong,
    .image_plans_div P,
    .advantagestn h4,
    .andoidcont h4 {
        font-size: 28px;
    }

    .pricestn h3 {
        margin-top: 15px;
    }

    .booknow a {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 35px;
    }

    .circlimg h2 {
        font-size: 38px;
        line-height: 40px;
    }

    .listpoint ul li {
        font-size: 18px;
        line-height: 24px;
    }

    .title h2,
    .title h3 {
        font-size: 44px;
        line-height: 50px;
    }


    .jabtout .butn {
        margin: 0;
    }

    .slick-dots {
        bottom: 5%;
    }

    .androidbox ul li p:last-child, body, .downloadbox h3 {
        font-size: 16px;
        line-height: 24px;
    }

    .technologystn .title h3 {
        line-height: 50px;
    }

    .advantageicon span,
    .circleicons ul li span {
        width: 150px;
        height: 150px;
    }

    .andoidcont h4 {
        line-height: 32px;
    }

    .listpoint ul li strong,
    .pricestn li strong,
    .andoidcont h4,
    .bundlebox ul li strong,
    .pricestn h3 strong,
    .circlimg h4 {
        font-size: 28px;
        line-height: 34px;
    }



    .bgbannerimg,
    .plans {
        border-radius: 200px 200px 0px 0;
    }

    .ftrbox ul li {
        font-size: 13px;
        line-height: 20px;
    }

    .footer:nth-child(2) .ftrbox ul li {
        line-height: inherit;
    }

    .copyrights p {
        font-size: 11px;
    }

    .jabcont .title h3 {
        margin-bottom: 10px;
    }

    .countnum,
    .countnum h3 {
        font-size: 36px;
    }

    .countnum p {
        font-size: 14px;
    }

    .androidtv .bundlebox h4,
    .bundlebox .setboxdetl h4 {
        font-size: 17px;
        line-height: 30px;
    }

    .setupboxtitle h4,
    .bundlebox h4 {
        padding: 10px 25px;
    }

    .circle01 {
        width: 100%;
    }

    .planbanner {
        height: 50vh;
    }

    .androidbox ul li p {
        font-size: 17px;
        line-height: 22px;
    }

    .androidtv .bundlebox h4,
    .bundlebox .setboxdetl h4 {
        font-size: 14px;
        line-height: 24px;
    }

    .jabcont .title h3 {
        line-height: 45px;
    }
}

@media all and (max-width:1440px) {
    .navbar .Srch_btn input[type="text"]{
        font-size: inherit;
        height: inherit;
    }
    .navbar ul li{
        padding: 0 5px;
    }
    .ftrbox ul li:first-child {
        font-size: 15px;
    }

    .bundlebox ul {
        margin: 25px 0;
    }

    .searchbox input[type="text"] {
        line-height: 40px;
        font-size: 16px;
    }

    .pricestn li:nth-child(1):before,
    .bannercont h2 {
        font-size: 40px;
    }

    .heading h4 {
        font-size: 26px;
        line-height: 32px;
    }

    .btechnoimg h4,
    .circleicons ul li h5 {
        font-size: 24px;
        line-height: 30px;
    }

    .technocont {
        padding: 15px;
    }

    .bundlebox ul li strong {
        font-size: 28px;
    }

    .appbox h3,
    .title h4,
    .squarecont li span,
    .pack h5 strong {
        font-size: 32px;
        line-height: 40px;
    }

    .appcta span {
        padding: 0 20px;
        font-size: 15px;
        line-height: 40px;
    }

    .featurebox span {
        font-size: 130px;
    }

    .form h2,
    .price h6 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .hungamacont ul li {
        font-size: 17px;
        line-height: 22px;
    }

    .labelcont p {
        font-size: 14px;
        letter-spacing: 2px;
        margin: 0;
        width: 100%;
    }


    .netplusadvantages h4,
    .advantagecont,
    .pack h5,
    .bundlebox ul li {
        font-size: 19px;
        line-height: 24px;
    }

    .footerform .form ul li,
    .booknow.filter .footerform.bookingform .form ul li:nth-child(2) {
        border-width: 1px;
    }


    .logo {
        width: 80px;
    }

    .navbar ul li a svg {
        width: 17px;
    }

    .jujhar {
        width: 90px;
        height: 40px;
    }

    .jujhar svg {
        width: 90%;
    }

    .slideimg img,
    .servicecont p,
    .circleicons ul li p,
    .smartcont {
        width: 100%;
    }

    .jabtout {
        width: 90%;
        margin: 0 5%;
    }

    .banner:before {
        width: 160px;
        height: 180px;
    }

    .fixed .logo {
        width: 80px;
    }

    .featurestn {
        width: 90%;
    }

    .dropdown li {
        font-size: 13px;
    }



    .squarecont li {
        padding: 25px;
    }

    .slick-dots {
        bottom: 3%;
    }

    section,
    .download.faqstn,
    .chooseplan,
    .plans,
    .faq .container,
    .androidtvrow,
    .number.aboutnetplus .container {
        padding: 50px 0;
    }

    .slick-slider .slick-next,
    .slick-slider .slick-prev {
        width: 45px;
        height: 45px;
    }

    .price h6 {
        margin: 0;
    }

    .hemburgur {
        margin-left: 35px;
    }

    .accImg {
        height: 20px;
    }

    .featurerow {
        margin-bottom: 15px;
    }


    .advantagesbox,
    .bundlebox,
    .plans.smarthome .count .App,
    .planbanner,
    .partnerbox {
        margin: 35px 0;
    }

    .price ul li:before {
        height: 1px;
    }

    .enquireform2 {
        padding: 80px 0;
    }

    .featurecont {
        padding: 50px 20px;
    }

    .technologystn .title h3 {
        line-height: 55px;
    }

    .dropdown .logo {
        left: 4.5%;
    }

    .menubox {
        width: 85%;
    }

    .fixed .dropdown .logo {
        left: 0.25%;
    }

    .solutionimg,
    .solutioncont {
        width: 48%;
    }

    .advantagesbox {
        width: 17%;
    }

    .banner.booknow.filter.m-0.h-120:before {
        height: 100px;
        width: 150px;
    }

    .circleicons ul {
        gap: 25px;
    }

    .verticaltabs ul.react-tabs__tab-list,
    .vertaltabbox {
        border-radius: 100px;
    }

    .title,
    .react-tabs__tab-list,
    .price,
    .heading,
    .planstab,
    .featurerow,
    .advantages,
    .hdbundle .gradienticons,
    .aboutnetplus,
    .App,
    .booknow.filter .footerform .form ul li,
    .leaddetails,
    .searchbox,
    .downloadstn {
        margin-bottom: 35px;
    }

    .contactbox h3,
    .contactbox li p strong {
        font-size: 21px;
        line-height: 30px;
    }

    .contactbox li span {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .smartcont {
        width: 100%;
    }

    .leaddetail img {
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .leaddetails h1 {
        font-size: 30px;
        line-height: 40px;
        border-left-width: 3px;
        padding-left: 20px;
    }

    .leaddetails h2 {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        border-left-width: 3px;
        padding-left: 20px;
    }

    .leaddetails:last-child {
        padding-top: 35px;
    }

    .technocont h4 {
        letter-spacing: 1px;
    }

    .downloadbox h3 {
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 20px;
    }

    .downloadbox a {
        padding: 0 10px;
    }

    .tabpanel .react-tabs__tab-panel li strong {
        font-size: 60px;
        line-height: 70px;
    }

    .verticaltabs ul.react-tabs__tab-list,
    .vertaltabbox {
        height: 250px;
    }

    .gradienticons ul li span {
        width: 100px;
        height: 100px;
    }

    .gradienticons ul li span img {
        width: 70%;
    }

    .price h6,
    .price h5 {
        font-size: 30px;
        line-height: 40px;
    }

    .price h5:before {
        font-size: 40px;
    }

    .setupboxtitle {
        top: 30px;
    }
    .ftrbox ul li:first-child {
        font-size: 16px;
        letter-spacing: 1px;
    }
}

@media all and (max-width:1420px) {
    .business .technobox {
        width: 20%;
    }

    .internal .banner {
        height: 70vh;
    }

    .dropdown .logo {
        left: 4%;
        top: 12%;
    }

    .fixed .dropdown .logo {
        left: 0.25%;
        top: 17%;
    }



    .contactoverview.contstn .office h4 {
        font-size: 24px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .contactoverview {
        margin-bottom: 20px;
        padding-bottom: 30px;
    }

    .contactoverview.contstn .contactbox.col-12 {
        margin: 0;
    }

    .contactbox {
        padding: 20px;
    }
}

@media all and (max-width:1380px) {
    .tabpanel .react-tabs__tab-panel li strong {
        font-size: 44px;
        line-height: 50px;
    }

    .home-slider .slick-dots li {
        width: 15px;
        height: 15px;
    }

    .ftrbox ul li {
        font-size: 12px;
    }

    .consumerbox span {
        width: 80px;
        display: inline-block;
    }

    .downloadbox h3 {
        font-size: 13px;
    }

    .androidtv .bundlebox h4,
    .bundlebox .setboxdetl h4 {
        font-size: 17px;
        letter-spacing: 0.5px;
    }

    .leftarrow,
    .rightarrow {
        width: 15px;
    }

    .internal .banner {
        height: 84vh;
    }

    .accordion .accordion-item.active .panel {
        padding: 15px;
    }

    .accordion .accordion-item .title {
        padding: 10px 20px;
    }

    .listpoint ul li strong,
    .heading h4,
    .btechnoimg h4,
    .circleicons ul li h5 {
        font-size: 20px;
    }

    .advantagestn h4 {
        line-height: 26px;
    }

    .pricestn li,
    .ptag h5,
    .servicecont h4 {
        font-size: 19px;
        line-height: 22px;
    }

    .pricestn h3,
    .androidtv .bundlebox h4,
    .androidbox ul li p:last-child {
        font-size: 14px;
    }

    .pricestn h3 strong,
    .pricestn li strong,
    .image_plans_div P,
    .accordion .accordion-item .title:after,
    .advantagestn h4,
    .bundlebox ul li strong {
        font-size: 24px;
    }



    .circlimg h2,
    .appbox h3,
    .title h4,
    .squarecont li span {
        font-size: 36px;
        line-height: 38px;
    }


    .title h2,
    .title h3,
    .bannercont h2,
    .countnum,
    .countnum h3,
    .plancont h4 {
        font-size: 34px;
        line-height: 42px;
    }

    .price h6,
    .bundlebox h4 {
        font-size: 32px;
        line-height: 40px;
    }

    .react-tabs__tab-list li,
    .labelcont p {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .advantagecont,
    .highlights ul li {
        font-size: 18px;
        line-height: 22px;
    }

    .androidbox ul li p:last-child {
        line-height: 18px;
    }

    .form ul li,
    .btn-11.fill-color {
        line-height: 30px;
    }

    .react-tabs__tab-list li,
    .androidtv .bundlebox h4 {
        line-height: 45px;
    }

    .buttnprop span {
        letter-spacing: 2px;
    }

    .copyrights p,
    .buttnprop span {
        font-size: 11px;
    }

    .squarecont li strong {
        font-size: 50px;
        line-height: 50px;
    }

    .dropdown .logo {
        left: 5.5%;
        top: 16%;
    }

    .fixed .dropdown .logo {
        top: 22%;
    }

    .menubox {
        padding-top: 20vh;
    }

    .title,
    .react-tabs__tab-list,
    .booknow.filter .footerform .form ul li,
    .heading {
        margin-bottom: 35px;
    }

    .butn,
    .servicecont {
        margin-top: 35px;
    }


    .title span,
    .bundlebox h4,
    .react-tabs__tab-list li {
        padding: 5px 25px;
        letter-spacing: 2px;
        line-height: 40px;
    }

    .sprice {
        margin-bottom: 15px;
    }

    .btechnocont li:before {
        width: 20px;
    }

    .circleicons ul li,
    .btechnoimg h4,
    .circleicons ul li h5 {
        margin: 0;
    }

    .banner:before {
        width: 140px;
        height: 140px;
    }

    .advantageicon span,
    .circleicons ul li span {
        width: 140px;
        height: 140px;
    }

    .hemburgur {
        width: 30px;
        margin-left: 20px;
    }

    header {
        height: 80px;
    }

    .ptag {
        width: 120px;
        height: 120px;
    }

    .hemburgermenus {
        width: 250px;
        padding: 20px;
        padding-bottom: 50px;
        border-radius: 0 0 0 70px;
    }

    .navbar ul li a.active:before {
        height: 1px;
    }

    .slideimg img {
        width: 90%;
    }

    .bundlebox ul li:before,
    .coreadvantages .squarecont ul li ul li::before {
        width: 40px;
        height: 0.5px;
    }

    #jujharnumber .buttnprop {
        width: auto;
    }

    #jujharnumber .buttnprop span {
        padding: 0 15px;
    }

    .footer {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    .ftrbox ul li:first-child {
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .form h2,
    .price h6 {
        font-size: 30px;
        line-height: 36px;
    }

    .freepacks li strong {
        font-size: 50px;
        line-height: 30px;
    }

    .pricestn {
        position: relative;
        z-index: 9;
    }



    .circle02 h2 {
        font-size: 26px;
        line-height: 26px;
    }

    .hemburgermenus .dropdown .sitemapcol ul li {
        margin: 3px 0;
        padding: 3px 0;
    }

    .ftrbox ul li:first-child {
        font-size: 14px;
        line-height: 22px;
    }

    .footerform .form ul li input {
        line-height: 35px;
    }

    .filter .form li select {
        height: 35px;
    }

    .contactbox h3 {
        font-size: 20px;
        letter-spacing: 1.5px;
        border-width: 3px;
        padding-left: 5px;
    }

    .contactbox li {
        margin: 5px 0;
        padding: 5px 0;
    }


    .contactbox li span {
        font-size: 12px;
        letter-spacing: 2px;
    }

    .listpoint ul li strong,
    .pricestn li strong,
    .andoidcont h4,
    .pricestn h3 strong,
    .circlimg h4 {
        font-size: 26px;
        line-height: 30px;
    }

    .shape {
        width: 220px;
        height: 150px;
    }

    .appbox h3,
    .title h4,
    .squarecont li span,
    .pack h5 strong {
        font-size: 26px;
        line-height: 28px;
    }

    .butn,
    .servicecont,
    .plansitem,
    .wrap,
    .androidpoints,
    .verticaltabs,
    .nowmorcont {
        margin-top: 30px;
    }

    [class^=btn-] span {
        padding: 0 25px;
        line-height: 36px;
    }

    .home-slider .slick-dots li button {
        padding: 0;
    }

    .slick-dots li button:before {
        font-size: 6px;
    }

    .accImg {
        height: 20px;
        width: auto;
        padding-right: 5px;
    }

    .searchbox {
        padding: 1px;
    }

    .searchbox input[type="text"] {
        font-size: 14px;
    }

    .enquireform2 {
        padding: 60px 0;
    }

    .jabcont .title h3 {
        line-height: 36px;
    }

    footer {
        padding: 30px 0 0 0;
    }

    .ftrbox ul li {
        letter-spacing: 0.5px;
    }

    .footer:nth-child(2) .ftrbox ul li {
        line-height: 24px;
    }

    .netplusadvantages h4,
    .advantagecont,
    .pack h5,
    .bundlebox ul li {
        font-size: 17px;
        line-height: 22px;
    }

    .prize {
        justify-content: flex-start;
    }

    .bundlebox {
        padding-left: 30px;
        padding-right: 30px;
    }

    .listpoint ul li {
        font-size: 16px;
        line-height: 22px;
    }

    .appbox h3,
    .title h4,
    .squarecont li span,
    .pack h5 strong {
        font-size: 22px;
        line-height: 30px;
    }

    .packstn .squarecont ul li strong,
    .bundlebox h4 {
        font-size: 30px;
        line-height: 30px;
    }

    .contactoverview.contstn .office h4 {
        font-size: 24px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .channelbox h3 {
        padding: 5px 15px;
        line-height: 28px;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
    .navbar ul li {
        line-height: 30px;
        font-size: 12px;
        letter-spacing: 1.5px;
    }

    .channelhead h4 {
        font-size: 22px;
        line-height: 30px;
    }

    .channelhead h5 {
        font-size: 17px;
        line-height: 20px;
    }

    .channelhead {
        margin: 5px 0;
        padding: 5px;
    }

    .channelstn {
        height: 40vh;
    }

    .channelrow {
        padding: 25px;
    }

    .closepop {
        width: 35px;
        height: 35px;
        font-size: 16px;
        top: 5px;
        right: 5px;
    }

    .channelhead h6 i {
        font-size: 21px;
    }

    .channelhead h6 {
        font-size: 16px;
    }

    .channelbox ul li {
        width: 60px;
    }

    .channelbox ul {
        gap: 10px;
    }

    .channelbox h3 {
        font-size: 12px;
    }

    body {
        font-size: 15px;
        line-height: 24px;
    }

    .circleicons ul li span img {
        width: 50%;
    }
}

@media all and (max-width:1360px) {
    .exclusivehead span {
        width: 35px;
        height: 35px;
    }

    .appbox h3,
    .title h4,
    .squarecont li span,
    .pack h5 strong {
        font-size: 28px;
        line-height: 30px;
    }

    .featurebox h4,
    .featureInfo {
        font-size: 19px;
        line-height: 22px;
    }

    .dropdown .logo {
        left: 6.5%;
    }

    .ftrbox ul li {
        letter-spacing: 0.5px;
    }

    .bundlebox ul li {
        padding: 5px 0;
    }

}

@media all and (max-width:1280px) {
    .Srch_btn input[type="text"]{
        width: 110px;
    }
    .fixed .headerstn {
        padding: 10px 0;
    }

    .hemburgermenus {
        width: 180px;
    }

    .sliderrow,
    .slideimg img {
        width: 100%;
    }

    .title h2,
    .title h3 {
        font-size: 32px;
        line-height: 40px;
    }

    .react-tabs__tab-list li {
        line-height: 35px;
        font-size: 11px;
    }

    .count {
        flex-wrap: wrap;
        margin-top: 25px;
    }



    .plans.smarthome .App::before {
        display: block;
    }

    .plans.smarthome .App:nth-child(1):before {
        display: none;
    }

    .smartcont {
        width: 80%;
    }

    .technologystn .title h3 {
        line-height: 44px;
    }

    .banner:before {
        width: 135px;
        height: 135px;
    }

    .jujhar {
        width: 70px;
    }

    .jujhar svg {
        width: 100%;
    }

    .dropdown .logo {
        left: 40px;
        top: 26px;
    }

    .fixed .dropdown .logo {
        top: 35px;
    }

    .title span {
        font-size: 13px;
        letter-spacing: 2px;
        line-height: 34px;
    }

    .jabtout {
        width: 100%;
        margin: 0;
    }

    body,
    .containers label {
        font-size: 14px;
        line-height: 22px;
    }

    .servicecont {
        margin-top: 15px;
    }

    .servicecont h4,
    .androidbox ul li p {
        font-size: 17px;
        line-height: 24px;
    }

    .slick-slider .slick-next,
    .slick-slider .slick-prev {
        width: 32px;
        height: 32px;
    }

    .appbox h3 {
        font-size: 22px;
    }



    .appleft {
        width: 40%;
    }

    .bannercont h2,
    .countnum,
    .countnum h3,
    .plancont h4 {
        font-size: 24px;
        line-height: 32px;
    }

    .btechnoimg h4,
    .technocont h4,
    .circleicons ul li h5 {
        font-size: 20px;
    }

    .banner.booknow.filter.m-0.h-120:before {
        height: 80px;
        width: 125px;
    }

    .advantagesbox {
        width: 25%;
    }

    .hdbundle::before,
    .squareshape::before,
    .enterprise::before,
    .partner::before,
    .androidtv::before,
    .androidtvrow::after,
    .androidbox::after {
        width: 220px;
        height: 220px;
    }

    .web-container {
        width: 85%;
    }

    .bundlebox h4 {
        font-size: 26px;
    }

    .gradienticons ul li span {
        width: 100px;
        height: 100px;
    }

    .advantagecont {
        height: 150px;
    }

    .banner,
    .number {
        height: 85vh;
        align-items: center;
    }

    .banner.booknow.filter.m-0.h-120 {
        height: 100px;
    }

    .ftrbox ul li:first-child {
        font-size: 14px;
        line-height: 20px;
    }

    .internal .banner {
        height: auto;
        margin-top: 100px;
    }

    #banner.internal.aos-init.aos-animate:before{
        height: 500px;
    }

    .labelcont p {
        padding: 0 20px;
        line-height: 16px;
        font-size: 10px;
    }

    .App {
        margin-bottom: 22px;
    }

    .App:before {
        width: 1px;
    }

    .numberconts {
        margin-top: 5vh;
    }

    .home-slider .slick-dots {
        right: -10%;
    }
}

@media all and (max-width:1152px) {
    .search_data_card {
        width: 48%;
    }
    .navbar ul li{
        font-size: 10px;
    }
    .Srch_btn input[type="text"] {
        width: 80px;
    }
    li.Srch_btn button img {
        width: 22px;
        height: 22px;
        filter: brightness(100%) invert(100%);
    }
    .navbar .Srch_btn input[type="text"] {
        height: 27px;
    }
    .live_tv iframe{
        width: 100%;
        aspect-ratio: 3/2;
        height: auto;
        }
    .googleicon {
        width: 50%;
        margin-right: 10px;
    }

    .shape {
        width: 180px;
    }

    .pricestn li strong {
        font-size: 21px;
        line-height: 28px;
    }

    .pack h5 {
        font-size: 15px;
        line-height: 20px;
    }

    .pack h5 strong {
        font-size: 22px;
    }

    .slidecont [class^=btn-] span {
        padding: 0 15px;
        line-height: 30px;
        font-size: 10px;
        letter-spacing: 2px;
    }

    .slidecont .wrap {
        margin-top: 15px;
    }

    .sliderrow {
        align-items: center;
    }

    .channelpack h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .circleicons ul li span {
        margin-bottom: 15px;
    }

    .circleicons ul {
        margin: 0;
    }

    .vtabstn {
        display: grid;
        text-align: center;
    }

    .vtabimg {
        width: 100%;
        text-align: center;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }

    .vtabcont {
        width: 100%;
    }

    .vtabstn .wrap {
        margin-top: 10px;
    }

    .vtabimg svg {
        width: 25%;
        margin: 0;
        display: flex;
    }

    .vtabcont ul li {
        list-style: none;
    }

    .verticaltabs ul.react-tabs__tab-list,
    .vertaltabbox {
        border-radius: 50px;
    }


    .banner:before {
        width: 115px;
        height: 115px;
    }

    .navbar ul li a svg {
        width: 12px;
    }

    .navbar ul:last-child {
        gap: 0px;
    }

    .navbar ul li:nth-child(7),
    .navbar ul li:nth-child(6) {
        padding: 0 7px;
    }

    .pricestn li {
        font-size: 16px;
        line-height: 18px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 36px;
    }

    .circlimg img {
        width: 75%;
    }

    .pricestn h3 {
        font-size: 13px;
    }

    .menubox {
        gap: 25px;
    }

    .sitemapcol h4 {
        font-size: 11px;
    }

    .pricestn h3 strong {
        line-height: 20px;
    }

    .dropdown .logo {
        left: 120px;
        top: 20px;
    }

    .pricestn li:first-child {
        margin: 0;
    }

    section,
    .download.faqstn,
    .chooseplan,
    .plans,
    .faq .container,
    .androidtvrow,
    .number.aboutnetplus .container {
        padding: 30px 0;
    }

    .web-container {
        width: 90%;
    }

    .appleft {
        padding: 40px 20px;
    }

    .appbox h3 {
        margin: 0;
    }

    .featurebox h4,
    .featureInfo {
        font-size: 17px;
        line-height: 20px;
    }

    .ptag {
        width: 100px;
        height: 100px;
    }

    .featurebox span {
        font-size: 100px;
    }

    .number .title {
        margin-bottom: 0;
    }

    .ftrbox ul li {
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 18px;
    }

    .ftrbox ul li:first-child {
        font-size: 11px;
    }

    .copyrights p {
        font-size: 9px;
        line-height: 30px;
    }

    .filter .form li select,
    .footerform .form ul li input {
        line-height: 30px;
        height: 30px;
    }

    .footerform .form ul li input::placeholder,
    .filter .form li select {
        letter-spacing: 1.2px;
    }

    .booknow.filter .footerform .form ul li textarea,
    .footerform .form ul li input::placeholder,
    .footerform .form ul li select,
    .footerform .form ul li input {
        font-size: 10px;
    }

    .btechnocont li {
        text-align: center;
    }

    .form h2 {
        font-size: 26px;
        line-height: 30px;
    }

    .technobox {
        width: 30%;
    }

    .circlimg h2,
    .appbox h3,
    .squarecont li span {
        font-size: 30px;
        line-height: 32px;
    }

    .circlimg h4 {
        font-size: 21px;
        line-height: 24px;
    }
    .search_cnt_data{
        justify-content: space-between;
        gap: 0;
        row-gap: 40px;
    }
}

@media all and (max-width:860px) {
    
    .navbar .Srch_btn{
        display: none;
    }
    .search_cnt .Srch_btn{
        display: flex;
        margin: 20px 0;
    } 
    .search_cnt .Srch_btn input[type="text"]{
        width: 100% ;
    } 
    .search_cnt .Srch_btn form{
        width: 100% ;
        padding: 0 20px ;
    }

    .search_cnt .Srch_btn button{
        width: auto !important;
    } 
    .dwm_pdf {
        width: max-content;
        position: static;
       transform: none;
        margin: 10px auto;
    }
    .submenu {
        background: transparent;
        display: flex !important;
        align-items: center;
        visibility: visible;
        opacity: 1;
    }
    .dropdown, .navbar ul li:hover .dropdown {
        height: 100vh;
        z-index: 99;
        background-color: #282828;
    }
    .bundlebox .btn-11 {
        position: relative;
        transform: inherit;
        width: 150px;
        height: 45px;
        left: 0;
    }
    .footerform .form ul li {
        width: 24%;
        margin: 0 0.5%;
    }
    .desktop-show,
    .navbar .desktop-show,
    .hdbundle::before,
    .partner::before,
    .enterprise::before,
    .squareshape::before,
    .broadband::before,
    .service:before,
    .retail::before,
    .androidtv::before,
    #banner:after,
    .hdbundle::before,
    .androidbox::after,
    .androidtv::before,
    .aboutus:after,
    .enterprise::before,
    .broadband::before,
    .service:before,
    .service .title::after,
    .app:after,
    .feature:after,
    .feature:before,
    .technologystn:after,
    .aboutus:before,
    .retail::before,
    .broadband::after,
    .squareshape::before,
    #banner:after,
    .partner::before,
    .androidtvrow::after,
    .hdbundle::after,
    .hemburgermenus,
    .navbar ul li a.desktop-show,
    .App:nth-child(odd):before,
    .hemburgermenus,
    .menu.active .hemburgermenus, .accImg {
        display: none;
    }

    .mobile-show,
    .navbar ul li a.mobile-show {
        display: block;
    }

    .home-slider .slick-dots {
        display: none !important;
    }

    .App {
        width: 50%;
        position: relative;
        height: auto;
    }

    .App:last-child:after,
    .App:nth-child(11):after {
        content: '';
        display: none;
    }

    .App:before {
        background: #de3b17;
        content: "";
        height: 100%;
        left: -3%;
        position: absolute;
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
        width: 1px;
    }
/* 
    .navbar ul li:hover .dropdown,
    .menu.active .hemburgermenus .dropdown {
        height: 100vh;
    }

    .dropdown {
        align-items: center;
        background: #4d4d4d;
        z-index: 99;
    }

    .menu.active .hemburgermenus .submenu,
    .navbar ul li:hover .dropdown .submenu {
        background-image: none;
        display: flex;
        align-items: center;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child,
    .menu.active .navbar ul li,
    .sitemapcol h4 {
        padding: 5px 0;
        margin: 5px 0;
        font-size: 19px;
        line-height: 30px;
        text-transform: capitalize;
        letter-spacing: 1px;
    } */

    .hemburgermenus .sitemapcol ul:last-child {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        border: none;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 16px;
    }

    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child,
    .sitemapcol h4 {
        border-bottom: solid 1px rgb(241 241 241 / 25%);
        font-weight: normal;
        font-family: "Franklin Gothic Book";
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li:last-child,
    .sitemapcol:last-child h4 {
        border: none;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li {
        width: 100%;
        border-bottom: 1px solid #282828;
    }

    .menu.active .navbar ul li {
        border-bottom: 1px solid hsla(0, 0%, 95%, .25);
        color: #fff;
    }

    .menu.active .navbar ul li:last-child {
        border: none;
    }

    /* #blacktext .fixed .topcta ul li a,
    #blacktext .navbar ul li a,
    .menu.active .navbar ul li a {
        color: #fff;
    } */

    .menubox {
        display: inline-block
    }

    .hemburgermenus .sitemapcol .mmenubox ul,
    .gradienticons ul {
        flex-wrap: wrap;
        margin: 0;
    }

    button.hemburgur.active span {
        background: #fff !important;
    }

    .hemburgermenus .sitemapcol ul li:nth-child(1) span:before {
        content: '\2039';
        font-size: 32px;
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        top: 35%;
        line-height: 0;
        font-weight: 100;
        font-family: system-ui;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li span {
        padding-left: 8px;
        color: #fff;
        opacity: 0.5;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 14px;
        position: relative;
    }

    .navbar ul:last-child {
        gap: 0;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 5%;
    }


    .dropdown .logo {
        left: 20px;
    }

    .navbar ul li, .navbar ul li:nth-child(7), .navbar ul li:nth-child(6), .sitemapcol h4 {
        width: 100%;
        float: left;
        padding: 3px 0;
        font-size: 15px;
        line-height: 30px;
    }

    .banner:before {
        display: none;
    }

    .jujhar {
        width: 60px;
        height: 30px;
    }
    .circle02 {
        width: 150px;
        height: 150px;
    }

    .circle02 h2,
    .advantagestn h4 {
        font-size: 22px;
        line-height: 24px;
    }

    .circlimg img {
        width: 70%;
        float: left;
        margin-left: 20%;
    }

    .pricestn li {
        font-size: 14px;
        line-height: 14px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 26px;
        left: 50%;
    }

    .listpoint ul li strong,
    .pricestn li strong,
    .pricestn h3 strong {
        font-size: 17px;
        line-height: 22px;
    }

    .pricestn {
        margin-top: 10px;
        text-align: center;
        position: relative;
        z-index: 9;
    }
    .sitemapcol h4{
        width:100%;
        float: left;
        z-index: 9;
    }
    .pricestn h3 {
        font-size: 11px;
    }

    .circlimg h2 {
        font-size: 28px;
        line-height: 32px;
    }


    .fixed .headerstn {
        padding: 5px 0;
    }



    .slick-dots li button:before {
        font-size: 5px;
    }

    .number .title {
        margin-bottom: 35px
    }

    .slick-dots li {
        width: 10px;
        height: 10px;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop {
        font-size: 9.5px;
    }

    .headerstn {
        justify-content: space-between;
        width: 100%;
    }



    .fixed .logo {
        width: 60px;
    }


    .hemburgermenus .dropdown {
        padding: 0;
    }

    .hemburgur {
        width: 50px;
        margin: 0;
        z-index: 99;
    }
    .logo{
        z-index: 99;
        position: relative;
    }
    .fixed .menu.active .hemburgermenus .submenu, .fixed .navbar ul li:hover .dropdown .submenu {
        height: 100%;
    }
    .leftarrow{
        top: 20%;
    }
    .leftarrow, .rightarrow {
        width: 10px;
    }
    .rightarrow{
        top: 40%;
    }
    .hemburgermenus .dropdown,
    .andoidcont,
    .jabcont,
    .aboutimg,
    .booknow .heading,
    .slideimg,
    .slidecont {
        width: 100%;
    }

    .sliderrow {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .slick-dots {
        bottom: 0;
    }

    .menu.active .hemburgermenus .submenu {
        display: flex;
        align-items: center;
    }

    .menu.active .hemburgermenus .dropdown {
        height: 100vh;
        align-items: inherit;
    }


    .hemburgermenus .sitemapcol {
        width: 90%;
        margin: 0px 5%;
    }

    .menubox {
        gap: 0;
        padding: 0;
        width: 100%;
        margin: 0;
    }

    header.whitelogo .logo path {
        fill: #fff !important;
    }
    .fixed .navbar ul li a, #blacktext .fixed .topcta ul li a, #blacktext .navbar ul li a{
        color: #fff;
    }
    .hemburgur.active span:nth-child(3),
    .hemburgur.active span:nth-child(1) {
        top: 25px;
    }

    .fixed .headerstn .menu {
        width: auto;
    }

    .menu.active .hemburgermenus .dropdown .dropdown.slideeffect {

        opacity: 1;
        width: 100%;
    }

    .menu.active .hemburgermenus .dropdown .dropdown {
        padding: 0;
        opacity: 0;
        height: 100vh;
        transform: initial;
        width: 0;
        position: fixed;
        background: #1c1c1e !important;
        display: flex;
    }

    .plans {
        border-radius: 0 0 0 200px;
        background-attachment: inherit;
    }

    .ftrbox ul li:first-child {
        margin-bottom: 5px;
        font-size: 15px;
    }

    .enquireform2 {
        border-radius: 70px 70px 0px 0;
        padding: 50px 0;
    }

    .bannercont {
        width: 60%;
    }

    .title p {
        width: 80%;
    }

    .circleicons ul {
        flex-wrap: wrap;
    }

    .circleicons ul li {
        width: 30%;
    }

    .circleicons ul li span {
        margin-bottom: 15px;
    }

    .squarecont ul {
        flex-wrap: wrap;
    }

    .squarecont li {
        width: 30%;
    }

    .countnum,
    .countnum h3,
    .solutioncont h4,
    .plancont h4 {
        font-size: 32px;
    }

    .advantagecont {
        padding: 20px;
    }

    .netplusadvantages h4 {
        font-size: 16px;
    }

    .androidfeature ul {
        gap: 20px;
    }

    .planbanner {
        border-radius: 100px 100px 0px 0px;
        height: 50vh;
    }

    .bundlebox h4 {
        padding: 10px 20px;
        font-size: 29px;
        line-height: 36px;
    }

    .androidbox .squarecont li {
        width: 80%;
    }

    .bundlebox {
        width: 45%;
    }


    .packs.coreadvantages .squarecont li {
        width: 46%;
    }

    .coreadvantages .squarecont li span {
        font-size: 24px;
        line-height: 30px;
    }

    .packstn .squarecont ul li strong {
        font-size: 28px;
        line-height: 30px;
    }

    .packs.coreadvantages .squarecont li ul li,
    .slick-track .slick-slide:nth-child(even) .sliderrow {
        width: 100%;
    }

    .packs .planbanner {
        background-attachment: inherit;
        height: auto;
        padding: 50px 0;
    }

    .hungamacircle {
        padding: 50px;
    }

    .hungamacircle {
        margin: 0px auto;
    }

    .hungamabox {
        flex-wrap: wrap;
    }

    .hungamabox {
        margin-bottom: 50px;
        display: grid;
    }

    .hungamafrow {
        width: 100%;
        margin: 0;
    }

    .highlights {
        margin-top: 25px;
        padding-top: 25px;
    }

    .highlights ul li {
        width: 50%;
    }

    .banner.jujhargroup {
        background-image: url(../images/mjujhar-brands.webp) !important;
        height: 100vh;
        margin-top: 10vh;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .jabcont {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    .jabcont .title {
        text-align: center;
    }

    .jabtout .butn {
        margin: 0px auto;
        display: flex;
        justify-content: center;
    }



    .banner.jujhargroup {
        margin-top: 5vh;
    }


    .medright,
    .medleft {
        width: 100%;
        margin-bottom: 25px;
    }

    .mediastn {
        flex-wrap: wrap;
    }

    .medleft ul {
        display: flex;
        padding: 15px;
        justify-content: center;
    }

    .medleft ul li {
        width: auto;
        margin: 0 20px;
        padding: 0 50px;
        border: none;
        border-right: 1px solid #a1a1a2;
        margin-right: 0;
    }

    .years ul {
        justify-content: center !important;
    }

    .fixed .hemburgur::before {
        opacity: 0;
    }


    .freepacks li strong {
        font-size: 40px;
        line-height: 10px;
    }

    .ftrbox {
        margin-bottom: 15px;
    }

    .ftrbox,
    .ftrbox.address,
    .footer:nth-child(2) .ftrbox {
        width: 50%;
    }

    .footer {
        flex-wrap: wrap;
        justify-content: space-between !important;
        padding: 50px;
        margin: 0;
    }

    .ftrbox:nth-child(1) {
        width: 100%;
        float: left;
    }

    .ftrbox:nth-child(1) svg {
        width: 30%;
    }

    .ftrbox {
        margin-bottom: 15px;
    }

    .ftrbox ul li {
        font-size: 14px;
        line-height: 26px;
    }

    .logo.active path.fil2:last-child {
        fill: #fff;
    }

    .menu .navbar {
        align-items: center;
    background-image:linear-gradient(80deg,rgba(21,97,169,1) 10%,rgba(238,49,71,1));
    bottom: 0;
    display: flex!important;
    flex-wrap: wrap;
    float: left;
    height: 100vh;
    left: 0;
    padding-top: 20px;
    position: fixed;
    top: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transition: all .5s ease;
    width: 100%;
    z-index: 4;
    }

    .menu.active .navbar {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    header {
        height: auto;
    }

    /* .navbar ul {
    flex-wrap: wrap;
        margin: 0 5%;
        width: 90%;
    } */

    .contactbox {
        width: 40%;
    }

    .navbar ul li a.active:before {
        bottom: -5px;
    }

    .banner.mngslide .sliderrow .slideimg img {
        left: -10%;
    }

    .internal .banner {
        height: auto;
        padding: 50px 0 0 0;
    }

    .mngcircle {
        text-align: center;
        margin-bottom: 50px;
    }

    .circlimg h4 {
        text-align: center;
    }

    .booknow.justify-content-start {
        justify-content: center !important;
    }

    #banner.internal.aos-init.aos-animate:before, #banner.internal::before  {
        height: 90%;
        background-image: url(../images/mbackground.webp);
    }
    .price h6::before{
        font-weight: normal;
    }
    .home-slider {
        float: left;
        width: 70%;
        margin: 0 15%;
        margin-bottom: 50px;
    }

    .verticaltabs .react-tabs {
        flex-wrap: wrap;
        gap: 10px;
    }

    .verticaltabs .react-tabs ul {
        width: 100%;
        height: auto;
        padding: 50px 0;
    }

    .verticaltabs .react-tabs .tabpanel {
        width: 100%;
    }

    .vertaltabbox {
        height: auto;
        padding: 50px;
    }

    .verticaltabs .react-tabs__tab-list li {
        padding: 0 15px;
        height: auto;
    }

    .channelpack h4 {
        height: 40px;
    }

    .number {
        height: auto;
        padding: 50px 0;
        background-image: url(../images/mobile-number.webp);
        margin-top: 25px;
    }

    .numberconts {
        margin-top: 0;
    }

    .App:before {
        opacity: 1 !important;
    }

    .labelcont p {
        margin-top: 10px;
    }

    .jabtout {
        display: grid;
        gap: 15px;
    }

    .verticaltabs .react-tabs .vtabcont ul {
        padding: 0;
    }

    .web-container {
        width: 100%;
    }

    .verticaltabs {
        margin-top: 0;
    }

    .setboxcont.bundlebox {
        width: 100%;
    }


    .prize {
        justify-content: center;
        gap: 15px;
    }

    .listpoint ul {
        text-align: left;
    }

    .banner {
        height: auto;
    }

    .download.faqstn .downloadbox,
    .technobox,
    .downloadbox {
        width: 46%;
    }

    .internal .banner .container {
        max-width: 100%;
    }

    .slideimg {
        flex-wrap: wrap;
        margin-top: 25px;
    }

    .technocont {
        height: 320px;
    }
    .grey-color.internal {
        background-color: #fff;
    }

    .setupboxtitle{
        top: 0;
    }
    .contactoverview.contstn .officebox {
        flex-wrap: wrap;
    }
    
    .contactoverview.contstn .officebox ul {
        width: 100%;
    }
    .setboxcont.bundlebox {
        margin-bottom: 50px !important;
    }
    
.vtabcont ul li {
    padding: 5px 0;
    margin: 5px 0;
    position: relative;
}

.vtabcont ul li:before {
    content: '';
    width: 30px;
    height: 1px;
    position: absolute;
    transform: translate(-50%,-50%);
    background: #fff;
    bottom: 0;
    left: 50%;
}

.vtabcont ul li:last-child:before {
    display: none;
}
.navbar ul li a.desktop-show {
    display: block;
}

.navbar ul li a.mobile-show {
    display: none;
}
}

@media all and (max-width:680px) {
  
    .search_data_card {
        width: 100%;
    }
    .googleicon {
        margin-right: 0;
    }
    .vtabimg svg {
        width: 50%;
    }

    .jujharnumber {
        padding: 50px 0;
    }

    .packmodule {
        gap: 10px;
    }

    .channelpack {
        width: 40%;
        padding: 30px 0;
    }

    .freepacks:nth-child(1) {
        padding: 5px;
    }

    .packmodule {
        margin: 25px 0 0 0;
    }

    .technocont ul li::before,
    .btechnocont li:before {
        left: 50%;
        transform: translate(-50%);
    }

    .contactbox,
    .technobox {
        width: 100%;
    }
    .technocont{
        height: auto;
        padding: 25px 10px;
    }
    .business .technobox {
        width: 45%;
    }

    .circleicons ul li span svg {
        width: 60px;
    }

    .contactoverview {
        flex-wrap: wrap;
    }

    .office {
        width: 100%;
        height: auto;
    }

    .plans.smarthome .count .App {
        width: 50%;
    }

    .price h6 {
        margin-bottom: 25px;
    }

    .plans.smarthome .count .App:nth-child(odd):before {
        display: none;
    }

    .androidfeature {
        margin-top: 50px;
    }


    .squarecont li strong {
        font-size: 40px;
        line-height: 40px;
    }

    .squarecont li span {
        font-size: 22px;
        line-height: 22px;
    }


    .pricestn li {
        font-size: 11px;
        line-height: 12px;
    }

    .pricestn li strong {
        font-size: 15px;
        line-height: 20px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 19px;
    }

    .pricestn h3 {
        font-size: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .pricestn h3 strong {
        margin-left: 5px;
    }

    .internal .banner {
        padding: 0;
    }

    .accordiancont p {
        margin-bottom: 5px;
    }

    .bundlebox {
        width: 47%;
        padding: 15px;
    }

    .highlights ul {
        flex-wrap: wrap;
    }

    .highlights ul li {

        margin: 15px 0;
    }

    .bundlebox .buttnprop {
        position: static;
        transform: inherit;
    }

    .setupbox {
        position: static;
        transform: inherit;
        width: 100%;
    }
    .protechnology {
        margin: 25px 0;
        gap: 20px;
    }
    .hdsetupbox {
        width: 80%;
        margin: 0 10%;
    }
}

@media all and (max-width:630px) {
    .search_cnt_data{
        justify-content: center;
    }
    ul#pagination,
    .search_cnt .Srch_btn input[type="text"]{
        width: 100% ;
    } 
    ul#pagination{
        overflow-x: auto;
        gap: 1%;
        justify-content: flex-start;
    }
    #pagination li {
        padding: 0 5px;
    }
    .search_cnt .Srch_btn form{
        width: 100% ;
        padding: 0 20px ;
    }
    .search_cnt .Srch_btn {
     margin: 10px 0;
    }
    .search_cnt .Srch_btn button{
        width: auto !important;
    } 
    .footer:nth-child(2) .ftrbox ul li:last-child:after {
        display: none;
    }
    .exclusivehead thead tr {
        background: #1d61a9;
    }
    .headerstn {
        padding: 10px 0;
    }

    .banner.mngslide .slidecont {
        justify-content: start;
        left: 0;
    }

    .verticaltabs {
        padding: 0;
        width: 100%;
    }

    .verticaltabs .react-tabs ul {
        padding: 25px 0;
        flex-wrap: wrap;
        background-color: transparent;
    }

    .vertaltabbox {
        padding: 25px;
    }

    .vtabstn .wrap {
        margin-top: 25px;
    }

    .verticaltabs .react-tabs__tab-list li {
        width: 50%;
        position: relative;
        padding: 30px 0;
        border-bottom: solid 1px #dbd5d5;
        height: 120px;
    }

    .verticaltabs .react-tabs__tab-list li svg {
        width: 50px;
        height: 50px;
    }

    .verticaltabs .react-tabs__tab-list li:nth-child(2) {
        border-right: none;
    }

    .verticaltabs .react-tabs__tab-list li:nth-child(3) {
        border-bottom: 0;
    }

    .popimg {
        width: 90%;
    }

    .planstab.antivirus .slick-slider .slick-next {
        right: -25px;
    }

    .planstab.antivirus .slick-slider .slick-prev {
        left: -25px;
    }

    .partnerbox ul li {
        width: 25%;
    }

    .lg-react-element a {
        width: 46%;
    }

    .banner.mngslide .slideimg {
        position: relative;
        right: -25%;
        margin-top: 25vh;
    }

    .hemburgermenus .sitemapcol ul,
    .dropdown li,
    .dropdown li a,
    .banner.mngslide .sliderrow, .hdsetupbox  {
        width: 100%;
        float: left;
    }

    .gradientapp {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .appleft,
    .apprgt,
    .appleft.form,
    .smartcont,
    .booknow.filter .footerform, .hdsetupbox  {
        width: 100%;
        position: static;
        border-radius: 0;
        margin: 0;
    }

    .advantagesbox {
        width: 46%;
        margin-bottom: 0;
    }

    .appleft {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .feartureimg,
    .featurecont,
    .aboutimg,
    .slick-track .slick-slide:nth-child(even) .sliderrow,
    .technology ul,
    .title h3 {
        width: 100%;
    }

    .featurerow {
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .featurecont {
        margin-top: 25px;
    }

    .plancont h4,
    .plancont h4 {
        font-size: 30px;
        line-height: 40px;
    }

    .price h6 {
        font-size: 40px;
    }

    .ptag {
        width: 120px;
        height: 120px;
    }

    .ptag h5 {
        font-size: 24px;
        line-height: 30px;
    }


    .jabcont {
        width: 100%;
        margin-top: 50px;
    }

    .form h2 {
        margin-bottom: 25px;
        padding: 0 20px;
    }

    .footerform .form ul li {
        width: 100%;
        margin-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .footerform .form ul li:last-child {
        margin-top: 35px;
    }

    .form {
        width: 70%;
        margin: 0px 15%;
    }


    footer .footer:nth-child(1) .ftrbox:first-child {
        width: 100%;
    }

    .ftrbox:first-child svg {
        width: 40%;
    }

    .copyrights {
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .enquireform2 .row {
        margin: 0;
        padding: 0;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        font-size: 13px;
    }


    .slick-track .slick-slide:nth-child(even) .sliderrow .slidecont {
        justify-content: start;
        left: 0;
    }


    .banner.h-auto .slick-list {
        overflow: visible;
    }

    #banner.aos-init.aos-animate:before {
        height: 50%;
    }

    .slick-dots {
        bottom: 3.5%;
    }

    .slick-dots li button:before {
        font-size: 7px;
    }

    .listpoint ul li strong,
    .pricestn h3 strong,
    .androidbox ul li p {
        font-size: 19px;
    }

    .chooseplan {
        border-radius: 50px;
    }

    .pricestn li {
        font-size: 20px;
        line-height: 26px;
    }

    .pricestn li strong {
        font-size: 26px;
        line-height: 32px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 40px;
        left: 50%;
    }

    .copyrights p {
        justify-content: center;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        line-height: 20px;
        font-size: 12px;
        letter-spacing: 0.5px;
        padding: 0 40px;
    }

    .advantagecont {
        padding: 20px;
        text-align: center;
        align-items: center;
    }

    .androidfeature ul,
    .bundlestn {
        gap: 0px;
    }

    .androidfeature ul li,
    .bundlestn ul li {
        margin: 15px 0;
    }

    .planbanner {
        border-radius: 100px 100px 0px 0px;
        height: 50vh;
    }

    .androidtvrow,
    .androidfeature {
        margin-top: 25px;
    }

    .plancont h4 {
        font-size: 24px;
        line-height: 30px;
    }

    .squarecont li {
        width: 48%;
    }

    .androidtvrow {
        flex-wrap: wrap;
        padding: 50px;
        text-align: center;
    }

    .androidbox7,
    .androidtvrow3 {
        width: 100%;
    }

    .androidtvrow:before {
        border-radius: 100px;
        right: 0;
    }

    .androidbox7 .buttnprop {
        justify-content: center;
        margin: 0px auto;
        margin-top: 25px;
    }

    .bundlebox ul li {
        padding: 5px 0;
        font-size: 19px;
        margin: 10px 0;
    }

    .price ul li {
        padding: 10px 0;
    }

    .ottslider {
        width: 90%;
        margin: 0px auto;
    }

    .image_plans_div P,
    .servicecont h4 {
        font-size: 21px;
    }

    .androidbox ul li p:last-child, body, .downloadbox h3 {
        font-size: 17px;
        line-height: 26px;
    }

    .footer:nth-child(2) .ftrbox ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .footer:nth-child(2) .ftrbox {
        width: 100%;
        margin-bottom: 20px;
    }

    .footer:nth-child(2) ul li {
        display: inline-block;
        position: relative;
    }

    .footer:nth-child(2) ul li:nth-child(1), .searchstn {
        width: 100%;
    }

    .footer:nth-child(2) .ftrbox ul li {
        font-size: 14px;
        position: relative;
        line-height: 22px;
        padding-right: 10px;
        margin: 0 0 10px 0;
    }

    .footer:nth-child(2) .ftrbox ul li:nth-child(1) {
        font-size: 15px;
        line-height: 22px;
    }

    .footer {
        padding: 35px;
    }

    .footer:nth-child(2) .ftrbox ul li:after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        background: #838383;
        right: 0;
    }

    .footer:nth-child(2) .ftrbox ul li:nth-child(1):after {
        opacity: 0;
    }

    .footer:nth-child(2) .ftrbox ul li:after {
        content: '';
    }

    .planstab .booknow a {
        color: #fff;
    }
    .number{
        padding: 25px 0;
        margin-top: 25px;
    }
    .leaddetails:last-child{
        margin-bottom: 0;
    }
    .setboxcont.bundlebox {
        flex-wrap: wrap;
    }
    
    .setboxdetl {
        width: 100%;
    }
    
    .setboxdetl ul {
        border: none;
        border-bottom: solid 1px #ededed;
        padding-bottom: 25px;
        text-align: center;
    }
    
    .hdsetupbox .bundlebox ul li:before {
        left: 50%;
        transform: translateX(-50%);
    }
    
    .setboxbuy {
        width: 100%;
    }
}

@media all and (max-width:480px) {
    .__search .heading{
        margin-bottom: 20px;
    }
   .search_cnt li.Srch_btn button img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
    .search_cnt .Srch_btn input[type="text"]{
        height: 40px;
        font-size: 16px;
    }
    .search_cnt .Srch_btn {
        margin: 0 auto;
        width: 95%;
        margin-bottom: 20px;
    }
    .prize{
     flex-direction: column;
    }
    .channel_title.channelbox h3, .channel_title{
        border-radius: 0;
        line-height: 1.2;
    }
    .bundlebox:last-child {
        margin: 0;
    }
    .packstn {
        width: 80%;
    }
    .slideimg .booknow {
        width: 250px;
        margin: 0px auto;
        margin-bottom: 25px;
    }
    .exclusivehead td, .exclusivehead p {
        font-size: 14px;
        line-height: 20px;
    }
    .ftrbox ul li:first-child,
    .footer:nth-child(2) .ftrbox ul li:nth-child(1) {
        margin-bottom: 0px;
        font-size: 17px;
        line-height: 30px;
    }

    .vtabcont ul li {
        margin: 5px 0;
    }

    .vtabcont ul li p {
        line-height: 26px;
    }

    .channelpack h4 {
        height: auto;
    }

    .channelpack h4 {
        font-size: 25px;
        line-height: 30px;
    }

    .mngcircle {
        margin-bottom: 0px;
    }

    .home-slider {
        width: 90%;
        margin: 0 5%;
        margin-bottom: 25px;
    }

    /* .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child,
    .menu.active .navbar ul li,
    .sitemapcol h4 {
        padding: 1px 0;
        margin: 1px 0;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
    } */

    section#faq.mobile-minus {
        margin-top: -50px;
    }

    .circle02 {
        top: -5%;
        right: 0;
    }

    .technology ul li {
        width: 28%;
    }

    .freepacks li span {
        padding: 2px 10px;
        letter-spacing: 1px;
        font-size: 8px;
    }

    .freepacks li strong {
        font-size: 32px;
        line-height: 32px;
        padding: 0;
    }

    .freepacks li strong small {
        font-size: 9px;
        letter-spacing: 1px;
        margin-left: 5px;
    }

    .appcta span {
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 13px;
        line-height: 40px;
    }


    .featurebox h4,
    .featureInfo {
        font-size: 22px;
        line-height: 24px;
    }

    .title {
        width: 100%;
        margin: 0;
        margin-bottom: 25px;
    }

    .planslider {
        width: 90%;
        margin: 0 5% !important;
        margin-bottom: 25px;
    }

    .bannercont h2 {
        font-size: 26px;
        line-height: 32px;
    }

    .banner.jujhargroup {
        margin-top: 5vh;
    }

    .circleicons ul li {
        width: 45%;
        align-items: flex-start;
    }

    .antiviruspacks {
        margin-top: 40px;
    }

    .smartcity .title p,
    .title p,
    .smartcont,
    .ottimg img {
        width: 100%;
    }

    .title h4,
    .pack h5 strong {
        font-size: 22px;
        line-height: 26px;
    }


    .advantagestn h4 {
        line-height: 26px;
    }

    .circleicons ul {
        gap: 25px;
    }

    .banner:before {
        width: 130px;
        height: 180px;
        background-size: contain;
        right: -16%;
    }


    .plans {
        border-radius: 0 0 0 100px;
    }

    .hemburgermenus .sitemapcol ul:last-child {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    }

    .hemburgermenus .sitemapcol ul:last-child li,
    .footerform.bookingform .form,
    .planstab.antivirus {
        padding: 0;
        margin: 0;
        width: 100%;
    }


    .servicebox {
        text-align: center;
        width: 100%;
    }

    .servicecont .butn {
        justify-content: center;
    }

    .servicecont p {
        height: inherit;
    }

    .technology ul li {
        width: 28%;
    }


    .copyrights p a {
        padding: 0 5px;
    }

    .circlimg img {
        margin-bottom: 10px;
    }

    .featurestn {
        width: 100%;
    }

    .channelpack {
        width: 80%;
    }

    .banner:before {
        width: 130px;
        height: 190px;
        right: -19%;
    }

    .planstab,
    .jabcont,
    .jabtout.advantagestn .aboutimg {
        margin: 0;
    }


    .form {
        width: 80%;
        margin: 0 10%;
    }

    .header.fixed {
        padding: 5px 0;
    }

    .sbanner {
        padding: 30px 0;
    }

    .solutionrow {
        display: grid;
        text-align: center;
        width: 100%;
    }

    .solutionimg,
    .solutioncont,
    .solutioncont p,
    .advantagesbox,
    .androidbox .squarecont li {
        width: 100%;
    }

    .solutioncont {
        margin-top: 25px;
        padding: 0 20px;
    }

    .advantagecont {
        height: auto;
        padding: 50px 25px;
        width: 100%;
    }

    .netplusadvantages h4,
    .pack h5,
    .medleft ul li,
    .eventsbox h5,
    .footerform .form ul li input::placeholder,
    .footerform .form ul li input,
    .booknow.filter .footerform .form ul li textarea,
    .footerform .form ul li input::placeholder,
    .footerform .form ul li select,
    .footerform .form ul li input {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 1px;
    }

    .form li select {
        letter-spacing: 0.5px;
    }

    .medright .react-tabs ul li {
        font-size: 11px;
    }

    .advantages {
        margin-bottom: 15px;
    }

    .medleft ul {
        padding: 10px;
    }

    .solutionimg img,
    .plancont,
    .androidfeature ul li {
        width: 80%;
    }

    .solutioncont h4 {
        font-size: 28px;
        line-height: 32px;
    }

    .aboutimg img {
        border-radius: 100px 0 0 0;
    }

    .planbanner,
    .number.aboutnetplus,
    .plans.smarthome {
        background-attachment: inherit;
    }

    .listpoint p {
        line-height: 18px;
        margin-bottom: 0;
    }

    .gradienticons ul li {
        width: 48%;
    }

    .androidfeature ul {
        flex-wrap: wrap;
    }

    .heading h4,
    .coreadvantages .squarecont li span {
        font-size: 19px;
        line-height: 22px;
    }

    .heading h4 {
        line-height: 24px;
    }

    .packstn .squarecont ul li ul li strong {
        font-size: 21px;
    }

    .packstn .squarecont ul li strong {
        font-size: 24px;
    }

    .bundlebox ul li strong {
        line-height: 30px;
        font-size: 26px;
    }


    .buttnprop span {
        font-size: 14px;
    }

    .bundlebox ul li:before,
    .coreadvantages .squarecont ul li ul li::before {
        width: 30px;
    }

    .bundlebox {
        width: 100%;
        padding: 20px;
    }

    .booknow.filter .footerform .form ul li,
    .hungamabox {
        margin-bottom: 25px;
    }

    .medleft ul li {
        padding: 0 20px;
        margin-left: 15px;
    }

    .medright .react-tabs ul {
        margin-bottom: 25px;
    }

    .eventsbox {
        width: 96%;
    }

    .lg-react-element a img {
        height: 200px;
    }

    .gallery .buttnprop {
        margin-left: 5vw;
    }

    .hemburgur::before {
        height: 180px;
        width: 180px;
        left: 180%;
        top: -5%;
    }

    .appbox h3 {
        font-size: 26px;
        line-height: 30px;
    }

    .plans.smarthome {
        padding-bottom: 50px;
    }

    .partnerbox ul li {
        width: 45%;
    }

    .androidtvrow3 img {
        width: 100%;
    }

    .footer:nth-child(1) {
        padding-bottom: 0;
    }

    .footer:nth-child(1) .ftrbox {
        margin-bottom: 25px;
        width: 46%;
    }

    .slidecont [class^=btn-] span {
        padding: 5px 25px;
    }

    .pricestn {
        margin: 0;
    }

    .footer:nth-child(2) .ftrbox ul li {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .footer:nth-child(2) .ftrbox ul li:after {
        display: none;
    }

    .circleicons ul li span img {
        width: 70%;
    }

    [class^=btn-] span {
        line-height: 50px;
    }

    .vtabstn .wrap [class^=btn-] span {
        line-height: 45px;
        padding: 0 30px;
    }

    .filter .form li select,
    .footerform .form ul li input {
        height: 40px;
        padding: 0;
        text-indent: 0;
    }

    .enquireform2 {
        padding: 30px;
    }

    .title h2,
    .title h3 {
        font-size: 30px;
        line-height: 36px;
    }
    .numberconts{
        margin: 0;
    }
    .ftrbox:first-child svg {
        width: 60%;
    }
    .entertainment {
        margin-bottom: 25px;
    }
    .service .circleicons ul li {
        width: 100%;
    }
    .planbanner {
        border-radius: 50px 50px 0px 0px;
        padding: 25px 0;
    }
}

@media all and (max-width:420px) {
    .circlimg h4 {
        font-size: 17px;
        line-height: 22px;
    }
    .form,
    .home-slider {
        width: 100%;
        margin: 0;
    }

    .download.faqstn .downloadbox,
    .technobox,
    .downloadbox {
        width: 100%;
    }
    .downloadbox a{
        height: auto;
        padding: 25px 10px;
    }
    .react-tabs__tab-list {
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .react-tabs__tab-list li {
        width: 48%;
        margin: 0;
        line-height: 16px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .channelpack {
        width: 100%;
    }

    .circleicons ul li p {
        padding: 0 20px;
    }

    .footer:nth-child(1) .ftrbox {
        width: 100%;
    }

    .jabtout {
        width: 90%;
        margin: 0px 5%;
    }

    .circle02 {
        right: 60%;
    }



    .hemburgur {
        width: 35px;
    }


    .netpluslide {
        padding: 0;
    }


    .plans.smarthome .count .App {
        width: 70%;
    }

    .App:last-child,
    .plans.smarthome .count .App:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .App:before {
        opacity: 0;
    }

    .count,
    .menu.active .hemburgermenus .dropdown .dropdown ul li, .hdplan {
        margin: 0;
    }

    .technology ul li {
        width: 40%;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child {
        font-size: 17px;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        font-size: 12px;
    }


    .apprgt img{
        width: 90%;
    }

    .bannercont h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .bannercont {
        width: 50%;
    }

    .andoidcont h4 {
        font-size: 22px;
        line-height: 26px;
    }

    .androidtvrow:before {
        border-radius: 50px;
    }

    .filter .footerform .form ul li:last-child,
    .androidpoints {
        margin-top: 25px;
    }
    .filter .footerform .form ul li:last-child{
        margin-bottom: 0;
    }
    .androidtvrow {
        padding: 25px;
    }


    .packs.coreadvantages .squarecont li,
    .booknow.filter .footerform .form ul li,
    .lg-react-element a {
        width: 100%;
    }


    .lg-react-element a img {
        height: auto;
    }

    .business .technobox,
    .androidfeature ul li,
    .technobox {
        width: 90%;
    }

    .technocont {
        text-align: center;
    }

    .number .title {
        margin-bottom: 20px;
    }

    .btechnoimg h4,
    .circleicons ul li h5 {
        font-size: 24px;
        padding: 0 10px;
        line-height: 30px;
        margin-bottom: 5px;
    }

    .countnum .labelcont p {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
    }

    .countnum p {
        font-size: 13px;
    }

    .countnum,
    .countnum h3 {
        font-size: 28px;
        position: relative;
    }
    .slideimg{
        margin:25px 0
    }
    
    .androidtv .bundlebox h4 {
        line-height: 25px;
    }

}

@media all and (max-width:400px) {
    .form {
        width: 90%;
        margin: 0 5%;
    }

    .labelcont p {
        font-size: 12px;
        line-height: 16px;
    }

    .pricestn li,
    .circle02 h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 30px;
    }

    .pricestn li strong {
        font-size: 22px;
        line-height: 30px;
    }

    .pricestn h3 {
        font-size: 13px;
    }

    .circle02 {
        width: 100px;
        height: 100px;
    }

    .pricestn {
        margin: 0;
    }

    .listpoint p {
        font-size: 15px;
        line-height: 20px;
    }

    .countnum,
    .countnum h3,
    .solutioncont h4,
    .plancont h4 {
        font-size: 28px;
    }

    .App {
        margin-bottom: 10px;
        padding-bottom: 5px;
    }
}

@media all and (max-width:375px) {
    .footer, .vertaltabbox {
        padding: 10px;
    }
    .headerstn {
        padding: 10px 0;
    }

    .logo {
        width: 80px;
    }
    .packstn {
        width: 94%;
    }
    .fixed .logo {
        width: 60px;
    }

    .partnerbox ul li {
        width: 45%;
    }

    .hungamacircle {
        padding: 20px;
    }

    .gradienticons ul li span img {
        width: 65%;
    }

    .listpoint p {
        font-size: 13px;
        line-height: 16px;
    }

    .web-container,
    .ftrbox.address,
    .ftrbox, .androidfeature ul li {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .squarecont li {
        padding: 25px 15px;
        width: 46%;
    }

    .squarecont li span,
    .heading h4 {
        font-size: 17px;
        line-height: 20px;
    }

    .squarecont li strong {
        font-size: 30px;
        line-height: 30px;
    }

    .banner:before {
        width: 120px;
        height: 150px;
        right: -20%;
    }


    .number .title {
        margin-bottom: 10px;
    }

    .circle02 {
        width: 90px;
        height: 90px;
    }

    .fixed .headerstn,
    #contactus .container {
        padding: 0;
    }
    .react-tabs__tab-list{
        margin-bottom: 15px;
    }
    .circleicons ul li {
        width: 100%;
        display: grid;
        justify-content: center;
        text-align: center;
    }

    .circleicons ul li span {
        margin: 0px auto 15px;
    }

    .booknow.filter .footerform .form ul li {
        margin-left: 0;
    }

    .coreadvantages .squarecont li span {
        font-size: 16px;
        line-height: 20px;
    }

    .packstn .squarecont ul li strong {
        font-size: 21px;
    }

    .listpoint ul li {
        font-size: 14px;
        line-height: 18px;
    }

    .footer {
        padding: 20px;
    }
}

@media all and (max-width:360px) {
    ul#pagination {
        gap: 0;
    }
    #pagination li {
        font-size: 10px;
    }
    #filter {
        padding: 25px 0;
    }
    .menu.active .hemburgermenus .dropdown .dropdown ul li, .hemburgermenus .dropdown .sitemapcol ul li, .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child, .menu.active .navbar ul li, .sitemapcol h4 {
        padding: 1px 0;
        margin: 1px 0;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
    .hemburgermenus .sitemapcol ul:last-child li {
        letter-spacing: 2px;
    }

    .gradienticons ul li span {
        width: 80px;
        height: 80px;
    }

    .accordion .accordion-item .title {
        padding: 15px;
        font-size: 14px;
        line-height: 16px;
    }


    .hemburgur span:nth-child(1) {
        top: 13px;
    }

    .hemburgur span:nth-child(3) {
        top: 32px;
    }

    .form h2 {
        font-size: 21px;
        line-height: 24px;
    }

    .enquireform2 {
        padding: 20px 0;
        border-radius: 50px 50px 0px 0;
    }

    .ftrbox ul li:first-child {
        margin-bottom: 5px;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child {
        font-size: 15px;
        line-height: 20px;
    }


    .listpoint ul li,
    .hemburgermenus .sitemapcol ul:last-child li {
        font-size: 10px;
        line-height: 12px;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        line-height: 24px;
    }


    .listpoint ul li strong,
    .title h4 {
        font-size: 16px;
    }


    .slick-dots {
        bottom: 4%;
    }

    .containers svg {
        width: 1rem;
        width: 1rem;
    }

    .servicebox,
    .gradienticons ul li {
        width: 100%;
    }



    .apprgt {
        padding: 20px;
        padding-bottom: 0;
    }

    .appbox h3 {
        font-size: 17px;
        line-height: 20px;
    }

    .bannercont h2 {
        font-size: 14px;
        line-height: 16px;
    }
    .title h2, .title h3 {
        font-size: 24px;
        line-height: 30px;
    }
    .App:before{
        opacity: 0 !important;
    }
    .App {
        width: 100%;
        border-bottom: solid 1px #343434;
        padding-bottom: 10px;
    }
    .androidbox ul li p:last-child, body, .downloadbox h3 {
        font-size: 15px;
        line-height: 22px;
    }
    [class^=btn-] span, .vtabstn .wrap [class^=btn-] span {
        line-height: 40px;
    }
    .jabcont .title h3{
        line-height: 30px;
    }
    .jabtout{
        width: 100%;
        margin: 0;
    }
}

@media all and (max-width:320px) {
    .appcta span {
        margin: 0;
        width: 100%;
        text-align: center;
    }

    .highlights ul li {
        width: 100%;
        margin: 5px 0;
    }

    .form h2 {
        font-size: 19px;
        line-height: 22px;
    }
    .searchbox input[type="text"] {
        font-size: 12px;
        letter-spacing: 0;
    }
    .heading h4 {
        font-size: 14px;
        line-height: 20px;
    }

    .heading, .bundlebox {
        width: 100%;
        margin-left: 0;
    }
}