@font-face {
    font-family: 'Franklin Gothic Book';
    src: url('FranklinGothic-Book.woff2') format('woff2'),
        url('FranklinGothic-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Franklin Gothic Demi Cond';
    src: url('FranklinGothic-DemiCond.woff2') format('woff2'),
        url('FranklinGothic-DemiCond.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Franklin Gothic Demi';
    src: url('FranklinGothic-Demi.woff2') format('woff2'),
        url('FranklinGothic-Demi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

