.enquireform2 {
    padding: 100px 0;
    border-radius: 100px 100px 0px 0;
}
.form{
    width: 100%;
    float: left;
}
.footerform .form ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* row-gap: 35px; */
}

.footerform .form ul li {
    width: 21%;
    margin: 0px 1%;
    border-bottom: solid 2px #000;
}
.copyrights {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.copyrights p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 50px;
    color: #979797;
    font-weight: 300;
    letter-spacing: 0.5px;
}
.copyrights p a{
  color: #979797;
    text-decoration: none;
}
.call {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.call a {
    color: #fff;
    text-decoration: none;
}

footer {
    padding: 80px 0 0 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}

.ftrbox:nth-child(1){
    width: 12%;
}

.ftrbox ul li {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 1px;
}

.ftrbox ul li:first-child {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 12px;
    font-size: 17px;
}

.ftrbox ul li a {
    text-decoration: none;
    transition: ease all 0.5s;
}

.navbar ul li a:not([href]):hover{
    color: #000;
  }

footer{
    width: 100%;
    float: left;
}
.form h2 {
    line-height: 30px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 43px;
    letter-spacing: 2px;
    margin-bottom: 50px;
    font-weight: 200;
}

.footerform .form ul li input::placeholder {
    color: rgb(255 255 255 / 70%);
}
.btn-11 input[type='submit'] {
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    font-size: 0;
    background: transparent;
    border: none;
}
.booknow.filter .footerform .form ul li textarea, .footerform .form ul li input::placeholder, .footerform .form ul li select, .footerform .form ul li input {
    letter-spacing: 2px;
    font-size: 12px;
    text-transform: uppercase;
}
.footer:nth-child(2) {
    justify-content: space-evenly;
    background: #f1f2f2;
}
.footer:nth-child(2) .ftrbox {
    width: auto;
}