body,
.highlights ul li span {
  color: rgb(0 0 0);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 28px;
  overflow-x: hidden;
  font-family: Poppins, sans-serif;
}

ul,
.coreadvantages .squarecont ul li ul li,
.packstn .squarecont li span,
.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.vtabcont ul li p {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

* {
  outline: 0;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
}

section,
.containers,
.title,
.butn,
.buttnprop span,
.heading,
.plans,
.planslider,
.pline,
.gradientapp,
.appcta,
.featurerow,
.number,
.count,
.technology,
.buttnprop a,
.jabtout,
.pricestn h3,
.pricestn,
.entertainment,
.banner,
.circleicons ul,
.circleicons,
.form li,
.squareshape,
.squarecont,
.accordion-item,
.advantages,
.booknow,
.gradienticons,
.gradienticons ul li p,
.plancont p,
.bundlestn,
.hemburgermenus .sitemapcol,
.coreadvantages .squarecont ul li ul li,
.planbanner,
.androidtvrow,
.androidtv,
.bundlebox .entertainment,
.bundlebox .entertainment ul,
.highlights,
.labelcont,
.partnerbox ul,
.partnerbox,
.androidfeature,
.react-tabs,
.verticaltabs .react-tabs,
.form ul li input[type="tel"],
.form ul li input[type="text"],
.form ul li input[type="email"],
.book,
.price,
.channel,
.planstab,
.bundlebox ul,
[class^=btn-] a,
.channelstn,
.contactbox .channelstnbox ul li strong,
.contactbox .channelstnbox ul li,
.search-fileter,
.searchbox,
.execlusivechannel,
.exclusivehead,
.slick-slider,
.verticaltabs .react-tabs__tab-list li span,
.protechnology,
.bst_cnt h2,
.bst_cnt p,
.downloadstn,
.channelbox {
  width: 100%;
  float: left;
}

.banner {
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}

.image_plans_div,
.pricestn .booknow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.gradienticons ul li p,
.title p {
  margin-top: 10px;
}


.pricestn li:nth-child(1):before,
.price h5:before {
  content: '\2715';
  position: absolute;
  font-size: 58px;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.price h5:before {
  color: #e23c4d;
  font-size: 60px;
}

.chooseplan,
.coreadvantages .squarecont ul li ul li {
  padding-top: 0;
}

.title,
.react-tabs__tab-list,
.price,
.heading,
.planstab,
.featurerow,
.advantages,
.hdbundle .gradienticons,
.aboutnetplus,
.App,
.booknow.filter .footerform .form ul li,
.leaddetails,
.searchbox,
.downloadstn {
  text-align: center;
  margin-bottom: 50px;
}

.jujhar a,
.containers,
.butn,
.buttnprop span,
.heading,
.react-tabs__tab-list,
.ptag,
.plansitem,
.gradientapp,
.apprgt,
.appcta,
.featurestn,
.featurerow,
.number,
.count,
.technology,
.technology ul,
.jabtout,
.sliderrow,
.circle01,
.booknow,
.serviceimg,
.circle02,
.advantagesbox,
.prize,
.gradienticons ul li span,
.androidtvrow,
.planbanner,
.plancont,
.plans.smarthome .count .App,
.circleicons ul li,
.advantagecont,
.advantageicon span,
.circleicons ul li span,
.androidtvrow3,
.gradienticons ul li,
.gradienticons ul,
.packstn .squarecont li,
.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.bundlebox .entertainment ul,
.highlights ul,
.countnum,
.partnerbox ul,
.verticaltabs .react-tabs,
.vtabstn,
.book,
.vertaltabbox,
.channel,
.nowmorcont a,
.searchstn,
.search-fileter,
.downloadstn,
.downloadbox a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plansitem,
.featurestn,
.technology ul,
.circle01,
.advantagesbox,
.plancont,
.plans.smarthome .count,
.plans.smarthome .circleicons ul,
.bundlestn,
.advantages,
.circleicons ul li,
.gradienticons ul li,
.coreadvantages .squarecont ul li ul,
.packstn .squarecont li,
.countnum,
.partnerbox ul,
.vtabstn,
.count,
.channel,
#locateus .contactoverview,
.searchstn,
.protechnology,
.downloadstn,
.downloadbox a {
  flex-wrap: wrap;
}

.banner.mngslide .sliderrow,
.featurerow:nth-child(even),
.jabtout.advantagestn,
.androidtvrow:nth-child(even) {
  flex-direction: row-reverse;
}

.heading,
.plans,
.pline ul li span:nth-child(1),
.featurebox,
.logo,
.topcta ul li,
.aboutus,
.featurebox,
.feature,
.app,
.service,
.technologystn,
.androidtvrow,
.banner,
.image_plans_div,
.containers svg,
.buttnprop span,
.heading h4,
.price h6,
.apprgt,
.appleft,
.App,
.pricestn li,
.circlimg,
.listpoint,
.logo,
.slideimg img,
.price ul li,
.advantagesbox span,
.bundlebox,
.bundlebox ul li,
.androidfeature ul li span,
.retail,
.broadband,
.squareshape,
.enterprise,
.partner,
.androidtv,
.coreadvantages .squarecont ul li ul li,
.footerform .form ul li,
.App,
.shape,
.nowmorcont a,
.price h5,
.coreadvantages .squarecont li,
.packstn .squarecont li span,
.searchbox,
.aboutfastway,
.channels,
.packs,
.hdplan,
.sdplans,
.androidpoints,
.coreadvantages,
.hdbundle,
.aboutnetplus,
.leadershipdetails,
.downloadbox a,
.smartcity,
.channelrow,
.channelpack h4,
.channelpack ul {
  position: relative;
}

.apprgt {
  justify-content: flex-end;
}

.servicecont .butn,
.appcta,
.jabcont .butn,
.buttnprop,
.androidfeature ul li h4,
.andoidcont h4,
.pricestn h3,
.bannercont ul,
.vtabstn .wrap {
  justify-content: flex-start;
  margin-top: 25px;
}

section,
.chooseplan,
.plans,
.faq .container,
.androidtvrow,
.download.faqstn {
  padding: 60px 0;
}

.featurestn {
  width: 75%;
  justify-content: space-evenly;
  margin: 0px auto;

}

#Bst_table tr:first-child,
#Refund_table tr:first-child,
.gradient,
.exclusivehead thead tr,
.searchbox,
.enquireform2,
.squarecont li span,
.bgcolor,
.title h2,
.title h3,
.androidtvrow:before {
  background: linear-gradient(80deg, rgba(21, 97, 169, 1) 20%, rgba(238, 49, 71, 1) 80%)
}

.squarecont li,
.bgcolor1 {
  background-image: linear-gradient(20deg, #e7ceeb, #fed0d3);
}

.container {
  max-width: 92%;
}

.jujhar a img,
.andoidcont,
.smartcont,
.title p {
  width: 60%;
  margin: 0px auto
}

.netpluslide,
.servicebox,
.plansitem,
.planslider,
.technology ul,
.jabtout,
.faqcont,
.aboutnetplus .title p,
.searchstn {
  width: 80%;
  margin: 0px auto;
}

.servicecont p,
.smartcont,
.circle01 {
  width: 90%;
}

.web-container {
  width: 77%;
  margin: 0px auto;
}

.serviceimg {
  height: 300px;
}

.planslider,
.jabtout {
  margin: 0 10%;
}

.butn,
.servicecont,
.plansitem,
.wrap,
.androidpoints,
.slidecont,
.verticaltabs,
.nowmorcont {
  margin-top: 50px;
}

#banner:before {
  content: "";
  background-image: url(../images/hom_banner_backgroun.webp);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background-size: cover;
  background-position: bottom;
  transition: ease all 1s;
}


.containers input,
.slick-next:before,
[dir="rtl"] .slick-prev:before,
.slick-next:before,
.slick-prev:before,
.contactpage .enquireform2 .form h2,
.App:nth-child(1):before {
  display: none;
}

@keyframes checked {
  to {
    stroke-dashoffset: 0;
  }
}

.faq .container,
.androidfeature ul li span img {
  border-radius: 25px;
}

.chooseplan {
  border-radius: 100px;
}


.squarecont li span,
.title h2,
.title h3 {
  color: #000;
  letter-spacing: 1px;
  font-size: 55px;
  line-height: 70px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-position: 0;
  background-size: 100% 100%;
  margin: 0;
  text-transform: capitalize;
}

.bannercont h2 {
  font-size: 52px;
  line-height: 50px;
  margin: 0;
}

.buttnprop {
  border: solid 1px #723ad6;
  width: 200px;
  height: 40px;
  display: flex;
}

.buttnprop span {
  background: #da1c38;
  top: -5px;
  left: -5px;
  font-size: 12px;
  letter-spacing: 2.6px;
  text-transform: uppercase;
  font-family: "Franklin Gothic Demi Cond";
}

.slick-dots li button:before {
  font-size: 8px;
  opacity: 1;
}

.home-slider .slick-dots li.slick-active button:before,
.slick-dots li.slick-active button:before {
  color: #b61d31;
}

.home-slider .slick-dots li button::before,
.andoidcont h4,
.andoidcont p {
  color: #fff;
}

.title span,
.bundlebox h4 {
  background: #e3e1e1;
  border-radius: 25px;
  font-size: 12px;
  letter-spacing: 4px;
  line-height: 50px;
  padding: 0 50px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 15px;
}

.title h4 {
  font-size: 33px;
  color: #000;
  line-height: 40px;
  letter-spacing: 1px;
  margin: 0;
}

.servicecont h4 {
  font-size: 23px;
  color: #000;
  font-family: "Franklin Gothic Demi Cond";
  padding-bottom: 10px;
  border-bottom: solid 2px #000;
}

.slick-slider .slick-prev {
  background-image: url(../images/left-arrow.webp);
}

.slick-slider .slick-next {
  background-image: url(../images/right-arrow.webp);
  background-position: right;
}

.planslider .slick-slider .slick-prev {
  background-image: url(../images/white-left-arrow.webp);
}

.planslider .slick-slider .slick-next {
  background-image: url(../images/white-right-arrow.webp);
}

.number {
  background-image: url(../images/numbers.webp);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.slick-slider .slick-next,
.slick-slider .slick-prev {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
}

.heading h4 {
  text-transform: uppercase;
  font-size: 29px;
  line-height: 40px;
  letter-spacing: 2px;
  padding-bottom: 10px;
  color: rgb(0 0 0 / 90%);
}

.heading h4:before {
  content: '';
  width: 50px;
  height: 2px;
  background: #d51e3f;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bgbannerimg,
.plans {
  background-image: url(../images/plans-banner.webp);
  border-radius: 500px 500px 0px 0;
  background-attachment: fixed;
}

.bgbannerimg {
  background-image: url(../images/whyfastway.webp);
}

.plans.smarthome {
  background-image: url(../images/home-automation-banner.webp);
  border-radius: 0;
}

.react-tabs__tab-list li {
  margin: 0 10px;
  background: #535355;
  padding: 0px 40px;
  border-radius: 25px;
  line-height: 55px;
  font-size: 13px;
  letter-spacing: 4px;
  cursor: pointer;
  outline: 0;
  text-transform: uppercase;
}

.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
  background: #ea3549;
}

.plansitem,
.featurestn,
.technology ul {
  background: #fff;
  border-radius: 25px;
}

.ptag {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  margin-top: -50px;
}

.ptag h5 {
  font-size: 27px;
  line-height: 30px;
  margin: 0;
  font-family: "Franklin Gothic Demi Cond";
  letter-spacing: 1px;
}

.pline {
  margin: 25px 0;
}

.pline ul li {
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
}

.pline ul li span:nth-child(1):before {
  content: "\2713";
  position: absolute;
  left: -60%;
  color: #378d00;
  font-weight: 900;
  font-size: 22px;
}

.price h6,
.price h5 {
  font-size: 42px;
  line-height: 50px;
}

.pricestn h3 strong,
.price h6,
.countnum p,
.netplusadvantages h4,
.heading h4,
.featureInfo,
.featurebox h4,
.packstn .squarecont ul li ul li strong,
.squarecont li span,
.title h2,
.title span,
.bundlebox h4,
.title h4,
.listpoint ul li strong,
.circlimg h4,
.price h5 {
  font-weight: normal;
}

.price h6:before {
  content: "\20B9";
  font-family: initial;
  font-weight: 100;
}

.sprice,
.jabcont h3,
.enterprise .title,
.partnerbox .heading,
.booknow.filter .footerform .form ul li:nth-child(9),
.aboutnetplus .title,
.smartcity .title,
.form li,
.search-fileter {
  margin-bottom: 25px;
}

.channelpack ul li,
.channelpack h4 {
  margin-bottom: 15px;
}

.pline ul li:first-child,
.bundlebox .entertainment,
.bundlebox .entertainment ul,
.plans.smarthome.aboutnetplus .App,
.plans.smarthome.aboutnetplus .count {
  margin-top: 0;
}

.mrp h6:after {
  content: "\002B";
  font-weight: 200;
  font-size: 100px;
  position: absolute;
  left: 55%;
  transform: translate(-50%, -50%) rotate(45deg);
  top: 50%;
  color: #ef0023;
}

.apprgt {
  width: 70%;
  padding: 50px;
  padding-bottom: 0;
  border-radius: 50px;
  right: 5%;
  background-image: linear-gradient(-120deg, #e7ceeb, #fed0d3);
}


.appleft {
  width: 30%;
  left: 5%;
  background: rgb(243 243 243 / 90%);
  padding: 50px 80px;
  z-index: 2;
}

.appbox h3 {
  font-size: 39px;
  line-height: 40px;
  font-weight: 600;
}

.appcta span {
  background: #ed145b;
  padding: 0 35px;
  line-height: 44px;
  margin-right: 15px;
  text-transform: uppercase;
  border-radius: 25px;
}

.featurecont {
  width: 50%;
  padding: 50px;
  border-radius: 50px;
  background-image: linear-gradient(-120deg, #e7ceeb, #fed0d3);
}


.aboutus:after {
  top: inherit;
  bottom: 0;
}


.featurerow,
.jabtout,
.prize,
.accordion .accordion-item .title,
.accortab ul li,
.booknow.filter .footerform .form ul,
.highlights ul,
.verticaltabs .react-tabs,
.vtabstn,
.search-fileter {
  justify-content: space-between;
}

.featurebox span {
  position: absolute;
  left: 0;
  font-size: 160px;
  color: rgb(0 0 0 / 10%);
  font-family: "Franklin Gothic Demi";
  line-height: 65px;
}

.featurebox h4,
.packstn .squarecont ul li ul li strong {
  font-size: 25px;
  font-family: "Franklin Gothic Demi";
  color: #000;
  text-align: left;
}

.featureInfo {
  text-align: left;
  font-size: 25px;
  width: 80%;
  margin: 0px auto;
}

.featureInfo>p,
.slick-dots li,
.advantagecont p,
.hemburgermenus .sitemapcol,
.packs .planbanner,
.booknow.filter .footerform .form ul li:last-child,
.technocont p {
  margin: 0;
}

.countnum,
.countnum h3 {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-family: Playfair Display, serif;
  font-size: 50px;
  font-weight: 400;
  justify-content: center;
  line-height: 60px;
  margin: 0;
}

.androidtv .bundlebox h4,
.bundlebox .setboxdetl h4 {
  margin: 0;
  font-size: 20px;
  line-height: 34px;
  text-transform: inherit;
}

.App,
.circleicons ul li {
  width: 20%;
}

.technology ul li {
  width: 20%;
  text-align: center;
  margin: 0 2%;
}

.aboutimg {
  width: 55%;
}


.jabcont,
.appleft.form,
.feartureimg {
  width: 40%;
}

.booknow.filter .footerform .form ul li {
  width: 45%;
}

.jabcont .title {
  text-align: left;
  margin: 0;
}

.aboutimg img {
  border-radius: 150px 0 0 0;
  width: 100%;
}

.pricestn h3 {
  margin-bottom: 0;
  font-size: 21px;
}

.bannercont,
.channelstnbox ul {
  width: 30%;
}

.listpoint ul li strong,
.pricestn li strong,
.andoidcont h4,
.bundlebox ul li strong,
.pricestn h3 strong,
.circlimg h4 {
  font-size: 32px;
  line-height: 40px;
}

.pricestn li {
  font-size: 28px;
  line-height: 28px;
  overflow: hidden;
}

.circle02 {
  width: 320px;
  height: 320px;
  position: absolute;
  top: -20%;
  border-radius: 50%;
  background: rgb(180 90 169 / 70%);
  z-index: 1;
  left: -10%;
}

.circle02 h2,
.plancont h4 {
  margin: 0;
  font-size: 40px;
  line-height: 40px;
}

.circlimg,
.listpoint,
.logo,
.slidecont {
  z-index: 2;
}

.slideimg img,
.contactbox.col-12 {
  width: 100%;
}

.androidfeature {
  margin-top: 80px;
}

.numberconts {
  margin-top: 25vh;
}

.banner.mngslide .sliderrow .slideimg img,
.slick-track .slick-slide:nth-child(even) .sliderrow .slideimg img {
  left: 0;
}

.slick-track .slick-slide:nth-child(even) .sliderrow .circle02 {
  left: inherit;
  right: 0%;
}

.finr:before {
  content: "\20B9";
  font-family: "Franklin Gothic Book";
  font-style: normal;
  font-weight: normal;
}

.circlimg h2 {
  font-size: 50px;
  line-height: 50px;
  text-transform: capitalize;
}

.listpoint ul li,
.bannercont ul li {
  font-size: 20px;
  line-height: 30px;
}

.listpoint ul,
.contentpage ul {
  list-style: disc;
  padding-left: 25px;
}

.slick-dots {
  position: absolute;
  bottom: 8%;
}

.price ul li,
.coreadvantages .squarecont ul li ul li {
  padding: 5px 0;
}

.price ul li:before {
  content: '';
  width: 20px;
  position: absolute;
  left: 50%;
  height: 2px;
  bottom: 0;
  transform: translate(-50%);
}

.entertainment ul {
  display: flex;
  width: max-content;
}

.entertainment ul li {
  padding: 7px;
}

.featurerow:last-child {
  margin-bottom: 0;
}

.gradienticons ul li span,
.plancont h4 {
  margin-bottom: 5px;
}

.bannercont {
  position: absolute;
  top: 35%;
  right: 5%;
}

.androidbox7,
.androidtvrow3,
.vtabimg,
.title p {
  width: 50%;
}

.vtabcont,
.verticaltabs ul.react-tabs__tab-list,
.tabpanel {
  width: 49%;
}

main #banner:after {
  top: 66%;
}

.setupboxtitle h4,
main .navbar ul li a,
.faq .heading h4,
.accordiancont p,
.coreadvantages .squarecont li p,
.navbar .sitemapcol h4 a,
.fixed .navbar .sitemapcol h4 a,
.booknow a,
.plancont,
.coreadvantages .squarecont ul li ul li,
.plans.smarthome .circleicons ul li,
.pack h5,
.bundlebox h4,
.accordiancont ul li,
.packstn .squarecont li strong,
.packstn .squarecont ul li ul li strong,
.buttnprop span,
.react-tabs__tab-list li,
.ptag h5,
.appcta span,
.countnum span,
.countnum,
.circle01,
.circle02 h2,
.plancont h4,
.bannercont,
.bgbannerimg .circleicons ul li p {
  color: #fff;
}

.advantages,
.squarecont ul,
.circleicons ul,
.androidfeature ul,
.bundlestn,
.protechnology {
  display: flex;
  justify-content: center;
  gap: 50px;
  text-align: center;
}

.advantageicon span,
.circleicons ul li span {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  box-shadow: 1px 1px 11px 1px rgb(85 85 85 / 18%);
  margin-bottom: 25px;
}

.circleicons ul li h5 {
  font-family: "Franklin Gothic Demi Cond";
  font-size: 28px;
  line-height: 30px;
  text-transform: capitalize;
}

.advantagesbox,
.bundlebox,
.plans.smarthome .count .App,
.planbanner,
.partnerbox,
.entertainment,
.protechnology {
  margin: 50px 0;
}

.filter .form li select {
  height: 50px;
  border: none;
}

.form li select {
  width: 100%;
  border: none;
  border-bottom: solid 2px #000;
  color: #000;
}

.appleft.form .buttnprop {
  justify-content: end;
  float: right;
}

.appleft.form {
  border-radius: 0 85px 0px 0;
}

.broadband .plansitem {
  border: solid 1px #c0c2c4;
}

.channelpack,
.squarecont li {
  padding: 30px;
  width: 24%;
  text-align: left;
  border-radius: 25px;
}

.squarecont li span {
  font-size: 36px;
  line-height: 40px;
  font-family: "Franklin Gothic Demi Cond";
  text-align: left;
}

.squarecont li strong {
  font-size: 74px;
  line-height: 80px;
}

.squarecont ul {
  gap: 10px;
}

.bundlestn,
.advantages,
.bundlebox .entertainment ul,
.channel {
  gap: 25px;
}

.accordion .accordion-item:first-child .title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion .accordion-item:last-child .title {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion .accordion-item .title {
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  background-color: #fff;
  display: flex;
  margin: 0;
  float: left;
  padding: 15px 20px;
  border-radius: 35px !important;
}

.accordion .accordion-item .title:after {
  color: #000;
  content: "\002B";
  float: right;
  font-size: 32px;
  font-weight: 300;
  margin: 0;
}

.accordion .accordion-item .panel {
  max-height: 0;
  -webkit-transition: max-height 0.15s ease-out;
  -o-transition: max-height 0.15s ease-out;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.accordion .accordion-item.active .title:after {
  content: "\2212";
}

.accordion .accordion-item.active .panel {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
  width: 100%;
  padding: 25px;
}

.calctab .accordion-item button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.accortab ul {
  flex-wrap: wrap;
  justify-content: start;
}

.accortab ul li {
  margin: 5px 0;
  width: 100%;
  float: left;
  display: flex;
}

.accortab,
.circleicons ul li,
.gradienticons ul,
.circleicons ul {
  margin: 10px 0;
  align-items: baseline;
}

.accordion-item {
  margin: 5px 0;
  border: none;
  background-color: transparent;
}

.pack h5,
.highlights ul li,
.squarecont li span,
.channelpack ul li {
  display: grid;
}

.coreadvantages .squarecont li span {
  -webkit-text-fill-color: #fff;
  padding-right: 40px;
  line-height: 36px;
  margin-bottom: 10px;
}

.booknow.filter .footerform {
  width: 70%;
  margin: 0px auto;
  clear: both;
}

.advantageicon span,
.advantagecont {
  background-image: linear-gradient(20deg, #e7ceeb, #fed0d3);
  border: none;
  box-shadow: inherit;
}

.advantagecont {
  padding: 50px;
  border-radius: 25px;
  font-size: 21px;
  text-align: left;
  height: 220px;
}

.advantagesbox span {
  margin: -20px;
}

.plans.smarthome .count .App {
  width: 30%;
}

.plans.smarthome.aboutnetplus .App {
  width: 20%;
}

.advantagesbox {
  width: 18%;
}

.netplusadvantages h4,
.androidbox ul li p {
  font-size: 25px;
  line-height: 30px;
}

.advantagestn h4 {
  font-size: 33px;
  line-height: 40px;
  font-family: "Franklin Gothic Demi";
  margin: 0;
}

.advantagestn li {
  margin: 25px 0px;
}

.pack h5 {
  margin: 0;
  font-size: 19px;
  line-height: 20px;
  text-align: left;
}

.pack h5 strong {
  font-size: 34px;
  line-height: 40px;
  font-weight: normal;
}

.login.contactpage .footerform .form ul li input,
.filter .footerform .form ul li input::placeholder,
.plans.smarthome.aboutnetplus .countnum,
.plans.smarthome.aboutnetplus .countnum span,
.contactpage .enquireform2 .footerform .form ul li input,
.contactpage .enquireform2 .footerform .form ul li input::placeholder,
.contactpage .enquireform2 .footerform .form ul li,
.login.contactpage .footerform .form ul li {
  color: #000;
  border-color: #000;
}

.login.contactpage .footerform .form ul li input::placeholder,
.booknow.filter .footerform .form ul li textarea::placeholder {
  color: #000;
}

.gradienticons ul li span {
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 25px;
}

.gradienticons ul li p,
.packstn .squarecont li,
.packstn .squarecont li span,
.containers label {
  font-family: Poppins, sans-serif;
}

.feartureimg,
.plancont,
.coreadvantages .squarecont ul li ul li,
.gradienticons ul li p,
.packstn .squarecont li,
.packstn .squarecont li span,
.smartcont,
.circleicons ul li p,
.channelpack,
.coreadvantages.androidbox ul li {
  text-align: center;
}

.androidtvrow:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  right: -30%;
  border-radius: 300px 0px 0 300px;
}

.androidfeature ul li h4,
.channelpack ul li {
  font-size: 24px;
  line-height: 30px;
}

.planbanner {
  background-image: url(../images/android-tv-banner.webp);
  height: 65vh;
  background-size: cover;
  background-attachment: fixed;
}

.packs .planbanner {
  background-image: url(../images/netplus-bundle.webp);
}

.planbanner {
  border-radius: 200px 200px 0px 0px;
}

.androidtvrow:nth-child(even)::before {
  right: inherit;
  left: -30%;
  border-radius: 0px 300px 300px 0px;
}


.bundlebox {
  border: #c7ded6 solid 1px;
  padding: 50px;
  width: 30%;
  box-shadow: 1px 1px 5px 1px rgb(85 85 85 / 18%);
  border-radius: 15px;
  padding-bottom: 80px;
}

.setupboxtitle h4,
.bundlebox h4 {
  background: #6d6e71;
  text-align: center;
  padding: 10px 40px;
  position: absolute;
  top: 0%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  border-radius: 50px;
  letter-spacing: 1px;
}

.packstn .squarecont ul li strong,
.bundlebox h4 {
  font-size: 36px;
  line-height: 50px;
}

.bundlebox ul li {
  padding: 10px 0;
  font-size: 21px;
  text-transform: capitalize;
}

.bundlebox ul li:before,
.coreadvantages .squarecont ul li ul li::before {
  content: '';
  width: 50px;
  height: 1px;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
}

.bundlebox ul li:before,
.coreadvantages .squarecont ul li ul li::before,
.price ul li:before {
  background: #000;
}

.bundlebox ul {
  margin: 40px 0;
}

#filter.p-40 {
  padding: 40px 0;
}

.bundlebox .buttnprop {
  justify-content: center;
  margin: 0px auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bundlebox ul li:last-child:before,
.coreadvantages .squarecont ul li ul li,
.plans.smarthome.aboutnetplus,
.form li select,
.form ul li input[type="tel"],
.form ul li input[type="text"],
.form ul li input[type="email"] {
  background: transparent;
}

.bundlebox ul li:last-child {
  padding: 0;
}

.androidfeature ul li span,
.coreadvantages .squarecont ul li ul li,
.packstn .squarecont li span {
  display: inline-block;
}

.androidfeature ul li span img:last-child {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
}

@-webkit-keyframes turn-7 {
  to {
    transform: rotate(0.500urn);
  }

  from {
    transform: rotate(0);
  }
}

@keyframes turn-7 {
  to {
    transform: rotate(0.875urn);
  }

  from {
    transform: rotate(0);
  }
}

.squareshape::before,
.enterprise::before,
.partner::before,
.androidtv::before,
.androidtvrow::after {
  width: 320px;
  height: 320px;
}

.accordiancont ul li {
  padding: 2px 0;
  list-style: square;
}

.accordiancont ul,
.bannercont ul {
  padding-left: 25px;
}

.bannercont ul {
  list-style: disc;
}

.bannercont ul li {
  margin: 0 20px;
}

.technology ul li img {
  filter: grayscale(1);
}

.technology ul li:hover img {
  filter: grayscale(0);
}

/* #retail,
#homeautomation,
#enterprise,
#smartcity {
  padding-top: 0;
  margin-top: -70px;
} */

section {
  overflow: hidden;
}

.androidtvrow::after {
  right: -35%;
  left: inherit;
  top: 35%;
}

.androidtvrow:nth-child(even)::after {
  right: inherit;
  left: -35%;
}


.setupboxtitle h4,
.channelpack,
.vertaltabbox,
.bundlebox h4,
.hemburgermenus,
.gradienticons ul li span,
.coreadvantages .squarecont li,
.faq .container,
.ptag,
.channelhead {
  background: linear-gradient(80deg, #1561a9 10%, #ee3147);

}

.submenu {
  background: linear-gradient(80deg, rgb(21 97 169 / 80%) 10%, rgb(238 49 71 / 80%));
}

.leaddetails h1,
.bundlebox ul li strong {
  font-family: "Franklin Gothic Demi Cond";
  font-weight: normal;
}

.packstn .squarecont li strong,
.packstn .squarecont ul li ul li strong {
  line-height: inherit;
}

.circleicons ul li span,
.coreadvantages .squarecont ul li ul li::before {
  background: #fff;
}

.coreadvantages .squarecont ul li ul li::before {
  width: 30px;
}

.coreadvantages .squarecont ul li ul li:last-child:before,
.price ul li:last-child:before,
.bundlebox .entertainment ul li:before,
.App:nth-child(5n+1):before {
  opacity: 0;
}

.booknow.filter .footerform .form ul li:nth-child(2),
.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.booknow.filter .footerform .form ul li:nth-child(9),
.form ul li input[type="tel"],
.form ul li input[type="text"],
.form ul li input[type="email"],
.verticaltabs .react-tabs__tab-list li:last-child,
.footerform .form ul li:last-child {
  border: none;
}

.booknow a,
.buttnprop span,
.technology ul li img {
  transition: ease all 0.5s;
}

.androidtv .androidtv {
  position: static;
}

.androidtv .androidtv:before {
  top: 25%;
  content: '';
  bottom: inherit;
}

.packstn .squarecont li span,
.bundlebox .entertainment li,
.accortab ul li {
  padding: 0;
}

.highlights {
  border-top: solid 1px #ededed;
  padding-top: 50px;
}

.lefttextalign {
  right: inherit;
  bottom: 50%;
  top: inherit;
  left: 20%;
}

.contactpage .enquireform2 {
  padding-top: 0;
  background: transparent;
}

.bundlebox h4 i {
  margin-right: 5px;
}

.h-120 {
  height: 120px;
}

.banner.h-120:before {
  top: 0;
  left: -4%;
  width: 200px;
  height: 120px;
}

.navbar ul li .dropdown .sitemapcol .active:before {
  background: none;
}

.countnum p {
  text-transform: uppercase;
  font-size: 19px;
  line-height: 27px;
  margin: 0 5px;
}

.labelcont p {
  font-family: 'Franklin Gothic Book';
  font-size: 17px;
  letter-spacing: 3px;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 5px;
  text-align: center;
  padding: 0 10px;
}

.App {
  height: 110px;
}

.verticaltabs {
  width: 90%;
  margin: 0px auto;
}

.highlights ul li {
  color: #de3b17;
  font-family: 'Franklin Gothic Demi Cond';
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 32px;
  text-align: center;
  width: 25%;
}

.App:before {
  background: #8f8f8f;
  content: "";
  height: 100%;
  position: absolute;
  width: 2px;
}

.partnerbox ul li {
  width: 14%;
  margin: 0 1%;
}

/* New CSS */
.banner.mngslide {
  height: 90vh;
}

.setupbox {
  position: absolute;
  bottom: -20%;
  right: 20%;
  transform: translate(-50%, -50%);
  width: 35%;
}

.shape {
  background-image: url(../images/price-shape.webp);
  width: 300px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  background-position: right;
}

.pprice {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: -15%;
}

.slideimg {
  width: 45%;
}

.slidecont {
  width: 55%;
}

.navbar ul li:last-child {
  padding-right: 0;
}

/* Button Css */

@-webkit-keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@-webkit-keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}


.btn-11:before,
.btn-11:after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #ea3549;
  border-radius: 50%;
}

.btn-11:before {
  left: -20px;
  transform: translate(-50%, -50%) scale(1.5);
}

.btn-11:after {
  right: -20px;
  transform: translate(50%, -50%);
}

.btn-11:hover:before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.btn-11:hover:after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

[class^=btn-] {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-transform: uppercase;
  border: 1px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50px;
  background-color: transparent;
  transition: ease all 2s;
}

[class^=btn-] span {
  padding: 0 35px;
  line-height: 50px;
}

.btn-11 span {
  font-size: 12px;
  letter-spacing: 2.5px;
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.home-slider .slick-dots {
  bottom: 50%;
  right: -15%;
  width: auto;
  display: grid !important;
}

.home-slider .slick-dots li {
  width: 100%;
}

.home-slider .slick-dots li button {
  width: auto;
  height: auto;
  margin: 0;
}

.grey-color {
  background-color: #f1f2f2;
}

/* Vertical tabs */

.verticaltabs .react-tabs__tab-list li {
  background: transparent;
  color: #000;
  border-right: solid 1px #dbd5d5;
  border-radius: 0;
  margin: 0;
  line-height: initial;
  display: flex;
  width: 25%;
  text-align: center;
  justify-content: center;
  padding: 0;
  flex-wrap: wrap;
  height: 70px;
  align-items: center;
}

.verticaltabs ul.react-tabs__tab-list {
  margin: 0;
  background: #f6f6f6;
  height: 300px;
  border-radius: 300px;
}

.verticaltabs .react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
  background: transparent;
  color: #ea3549;
}

.vertaltabbox {
  padding: 0 50px;
  border-radius: 300px;
  height: 300px;
}

.vtabcont ul li,
.btn-11.fill-color span,
.gredientbtn a,
.channelpack h4,
.channelpack ul li strong,
.channelpack ul li {
  color: #fff;
}

.vtabcont ul {
  list-style: disc;
  padding-left: 15px;
}

.title p {
  margin-top: 10px;
}

.verticaltabs .react-tabs__tab-list li svg {
  width: 35px;
  margin-bottom: 5px;
  float: none;
  display: flex;
  justify-content: center;
}

.btn-11.fill-color {
  background: #ea3549;
  height: auto;
  border: solid 1px #ededed;
}

.form ul li,
.btn-11.fill-color {
  line-height: 50px;
}

.btn-11.fill-color:hover {
  border-color: #1d61a9;
}

.btn-11.fill-color:before,
.btn-11.fill-color:after {
  background: #1d61a9;
}

.tabpanel .react-tabs__tab-panel:nth-child(3) li,
.tabpanel .react-tabs__tab-panel:nth-child(4) li {
  list-style: none;
  display: grid;
  line-height: initial;
}

.tabpanel .react-tabs__tab-panel li strong {
  font-size: 80px;
  line-height: 80px;
}

.channelpack h4,
.channelpack ul li strong {
  font-size: 28px;
  line-height: 36px;
}

.channelpack ul li strong small {
  font-size: 14px;
  font-weight: normal;
  margin-left: 5px;
}

.nowmorcont a {
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
  color: #fff !important;
  cursor: pointer;
}

.nowmorcont a:after {
  content: '\2192 ';
  font-size: 32px;
  line-height: 0;
  position: relative;
  top: -4px;
  left: 5px;
  transition: ease all 0.5s;
}

.enquireform2 .footerform .form ul li {
  border-color: #fff;
}

.countnum sup {
  font-size: 17px;
  top: -1em;
}

.App,
.booknow.filter .footerform .form ul li,
.leaddetails {
  text-align: left;
}

#contactus .enquireform2 .footerform .form ul li.btn-11.fill-color {
  border: solid 1px #fff;
}

#contactus .enquireform2 .footerform .form ul li.btn-11.fill-color:hover {
  border-color: #1e61a9;
}

.internal .banner {
  height: 86vh;
}

#banner.internal.aos-init.aos-animate:before {
  height: 90%;
}

.bgbannerimg {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.androidbox ul li p {
  letter-spacing: .5px;
  margin-top: 10px;
  padding: 0 10px;
}

.androidbox ul li p:last-child {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0;
}


.image_plans_div P {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 35%;
  -webkit-transform: rotate(-3deg);
  transform: rotate(-3deg);
  width: 100%;
  z-index: 3;
}

.image_plans_div p span {
  font-size: 15px;
}

.apps img {
  width: 30%;
}

.apps {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
  margin-top: 25px;
}

.price.ottprice {
  border-top: 1px solid #ededed;
  margin-top: 30px;
  padding-top: 30px;
}

.entertainment .slick-slider .slick-next,
.entertainment .slick-slider .slick-prev {
  width: 30px;
  height: 30px;
}

.price.ottprice small {
  font-size: 16px;
}

.image_plan:after {
  background-image: url(../images/Plans_effect.webp);
  background-size: contain;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 8%;
  width: 100%;
  z-index: 5;
}

.packs.coreadvantages ul li::before {
  content: '';
  background-image: url('../images/dotted-design.webp');
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

#blacktext .fixed .topcta ul li a,
#blacktext .navbar ul li a {
  color: #000;
}

#blacktext .hemburgur span {
  background: #000;
}

#filter {
  padding: 40px 0;
}

.desktop-show {
  display: block;
}

.mobile-show {
  display: none;
}

.jujharnumber {
  background-attachment: fixed;
  background-image: url('../images/number-banner.webp');
  background-position: 50%;
  background-size: cover;
  padding: 100px 0;
}

#blacktext header {
  position: inherit;
  float: left;
}

.booknow.filter .footerform .form ul li textarea {
  border: none;
  padding: 0;
  resize: none;
  width: 100%;
  height: 50px;
}

.booknow.filter .footerform.bookingform .form ul li:nth-child(2) {
  border-bottom: 2px solid #000;
}

.form li,
.channelname {
  display: flex;
}

.plans.innerpack {
  background: transparent;
  padding: 0;
}

.leaddetails {
  width: 100%;
  float: left;
}

.leaddetails img {
  filter: grayscale(1);
  transition: 0.2s;
}

.leaddetails img:hover {
  filter: none;
}

.leaddetails h1 {
  font-size: 41px;
  margin: 0;
  line-height: 45px;
  letter-spacing: 1px;
  font-weight: normal;
  border-left: 5px solid #ea3549;
  padding-left: 30px;
}

.leaddetails h2 {
  font-size: 19px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ea3549;
  border-left: 5px solid #ea3549;
  padding-left: 30px;
}

.leaddetail {
  margin-top: 40px;
}

.leaddetail img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  float: left;
  margin-right: 30px;
  position: relative;
  margin-bottom: 10px;
}

.leaddetail .btn-3 {
  margin-top: 60px;
}

.leaddetail p {
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.leaddetails:last-child {
  border-top: solid 1px #ededed;
  padding-top: 50px;
}

.hdsetupbox {
  float: left;
  width: 60%;
  margin: 0 20%;
  position: relative;
}

.setboxcont,
.channelname {
  width: 100%;
  display: flex;
  float: left;
  align-items: center;
}

.setupboxtitle {
  width: 100%;
  text-align: center;
  position: relative;
  top: 40px;
  z-index: 1;
}

.setboxdetl h4 {
  left: 50%;
}

.hdsetupbox .bundlebox ul li:before {
  left: 0;
  transform: inherit;
}

.setboxdetl {
  display: flex;
  align-items: center;
  width: 65%;
}

.setboxbuy {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.setboxbuy .price h5:before {
  left: 50%;
}

.setboxdetl ul {
  margin: 20px 0;
  border-right: solid 1px #ededed;
}

.setboxbuy .wrap {
  width: 100%;
  float: left;
  margin: 0;
}

.bundlebox .setboxbuy .wrap {
  width: auto;
  float: none;
}

.setboxcont.bundlebox .btn-11.fill-color {
  transform: inherit;
  position: relative;
  left: 0;
}

#blacktext {
  overflow: visible;
}

.banner.jujhargroup {
  height: 90vh;
}



.contactoverview {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #ededed;
  margin-bottom: 70px;
  padding-bottom: 50px;
}

.contactbox {
  width: 28%;
  margin: 0 1%;
  margin-bottom: 25px;
  padding: 30px;
  border: solid 1px #f5f3f3;
  border-radius: 50px;
}

.contactbox h3 {
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 32px;
  text-transform: uppercase;
}

.contactbox li p {
  display: grid;
}

.contactbox li {
  border-bottom: solid 1px #ededed;
  padding: 10px 0;
}

.contactbox li span {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  margin-bottom: 5px;
  display: inline-block;
  color: #ea3549;
}

/* .disabled {
  background-color: #a0a2a4 !important;
  color: #696b6f !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
} */

.contactbox li:last-child {
  border: none;
}

.contactoverview:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.channelstn::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.channelstn::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.channelstn::-webkit-scrollbar-thumb {
  background-color: #382323;
  border: 1px solid #555555;
}

.contactbox .channelstnbox ul li {
  margin: 0;
}

.contactbox .channelstnbox ul li span a {
  border-bottom: solid 1px #070707;
  transition: ease all 0.5s;
  padding-bottom: 5px;
}

.contactbox .channelstnbox ul li span:hover a {
  border-bottom: solid 2px #d12341;
  color: #d12341;
}

.channelstnbox {
  display: flex;
  gap: 50px;
}

.channelstn h4 {
  font-size: 30px;
  font-family: "Franklin Gothic Demi Cond";
  letter-spacing: 1px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}


#Bst_table td,
#Refund_table td,
.vtabcont ul li,
.filterright select,
.searchbox input[type="text"],
.contactbox li,
.image_plans_div P,
.androidbox ul li p:last-child,
.listpoint ul li,
.smartcont p,
.circleicons ul li p,
.contentpage p,
.contentpage ul li,
.contentpage ol li,
.jabcont p,
.title p,
.leaddetail p,
.contactbox li p,
.technocont p,
.exclusivehead p {
  font-family: Franklin Gothic Book;
}

.contactbox .channelstnbox ul li strong {
  font-family: Poppins, sans-serif;
}

.contactbox li p strong {
  font-size: 36px;
}

.searchbox {
  width: 100%;
  border-radius: 50px;
  border: solid 1px #ededed;
  font-size: 19px;
  letter-spacing: 1px;
  padding: 2px;
}

.searchbox button {
  position: absolute;
  right: 0;
  border: none;
  background: transparent;
  top: 50%;
  transform: translate(-50%, -50%);
}

.filterright select {
  padding: 5px 10px;
  border: solid 1px #d1d1d1;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.filterleft h4 {
  margin: 0;
  font-size: 22px;
  line-height: 30px;
}

.searchbox input[type="text"] {
  width: 100%;
  line-height: 50px;
  border-radius: 50px;
  border: solid 1px #ededed;
  font-size: 19px;
  letter-spacing: 0.5px;
  text-indent: 20px;
}

.filterright select option {
  font-size: 16px;
}

.exclusivehead thead tr th {
  color: #fff;
  font-weight: normal;
  padding: 10px;
}

.exclusivehead tbody {
  border-color: #fff;
}

.exclusivehead span {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border: solid 1px #dfdfdf;
  border-radius: 100%;
  padding: 2px;
  float: left;
  background: #f9f9f9;
  overflow: hidden;
}

.exclusivehead th {
  font-weight: normal;
  padding: 5px 0px;
}

.contactbox.gradient p {
  margin: 0;
  width: 100%;
  float: left;
  text-align: center;
}

.contactbox.gradient li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.contactbox.gradient {
  padding: 0;
  width: 220px;
  line-height: 50px;
}

.contactbox.gradient ul li a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.verticaltabs .react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected path {
  fill: #ea3549;
}

.vtabimg svg {
  width: 50%;
  margin-left: 25%;
  color: #fff;
  fill: #fff;
}

.leftarrow,
.rightarrow {
  position: absolute;
  width: 20px;
}

.rightarrow {
  right: 2%;
  top: 65%;
  transform: scaleY(-1);
}

.leftarrow {
  left: 2%;
  top: 50%;
}

.white path {
  fill: #fff !important;
}

.jabcont .title h3 {
  line-height: 55px;
  width: 100%;
}

.navbar ul li a[href='#'] {
  cursor: default;
}

.technobox,
.downloadbox {
  width: 25%;
}

.technoimg img {
  width: 100%;
}

.technocont {
  background: #f1f1f1;
  padding: 25px;
  height: 420px;
}

.technocont h4 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
  letter-spacing: 2px;
}

.protechnology,
.downloadstn {
  gap: 25px;
}

.downloadbox a {
  background: #f7f7f7;
  border-radius: 25px;
  width: 100%;
  height: 180px;
  padding: 0 50px;
  border: solid 1px #f3f3f3;
}

.downloadbox h3 {
  font-size: 17px;
  text-align: center;
  margin: 0;
  letter-spacing: 1px;
}

.downloadbox a:before {
  content: '';
  background: url(../images/pdf-file.png);
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
}

.downloadbox a svg {
  width: 35px;
  margin-top: 20px;
}

.chanpackpop {
  position: fixed;
  background: rgb(0 0 0 / 50%);
  z-index: 99;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.channelrow {
  width: 80%;
  background: #fff;
  padding: 50px;
}

.channelhead {
  width: 100%;
  float: left;
  margin: 10px 0;
  border: solid 1px #ededed;
  padding: 10px;
  text-align: center;
  margin-top: 0;
  border-radius: 25px;
}

.channel_title.channelbox h3,
.channel_title {
  padding: 10px 20px;
  background: rgb(255 239 239);
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 1px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.channel_title p {
  margin: 0;
  padding-right: 10px;
}

.channel_title p:nth-child(2):before {
  content: "\20B9";
  font-size: inherit;
  font-family: initial;

}

.live_tv iframe {
  height: 620px;
  width: 1080px;
}

.live_tv {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.channelbox ul {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: baseline;
}

.channelbox ul li {
  width: 80px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 5px;
  margin: 5px 0;
  word-break: break-all;
}

.channelstn {
  float: left;
  width: 100%;
  height: 50vh;
  overflow: auto;
  padding: 25px;
  border-bottom: solid 1px #ededed;
}

.channelhead h4 {
  font-size: 28px;
  line-height: 36px;
  color: #fff;
  letter-spacing: 1px;
  margin: 0;
}

.channelhead h5 {
  font-size: 22px;
  line-height: 30px;
  margin: 0;
  color: #fff;
}

.channelhead h6 {
  color: #fff;
  font-size: 19px;
}

.closepop {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #feefef;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 26px;
  cursor: pointer;
}

.chanpackpop.active {
  display: flex;
}

.channelstn::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.channelstn::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.channelstn::-webkit-scrollbar-thumb {
  background-color: #382323;
}

.channelpack {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.download.faqstn .downloadbox {
  width: 22%;
}

.channelpack:before {
  content: '';
  background: #1d61a9;
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  top: 0;
  transition: ease all 0.5s;
}

.channelpack:hover:before {
  height: 100%;
}

.footer:nth-child(2) .ftrbox ul li {
  line-height: 30px;
}

/* ------------Refund Css------------------- */

#Refund_table {
  margin: 50px 0;
}

#Bst_table th,
#Refund_table th {
  color: white;
  text-align: center;
}

.bst_cnt h2,
.bst_cnt p,
#Bst_table td,
#Refund_table td {
  text-align: center;
  padding: 10px;
}

.bst_cnt h2,
.bst_cnt p {
  margin: 0 !important;
  background-color: #92c1eb4b;
  text-align: center;
}

/* MUTIPLE TV POLICY */
.fixed .navbar ul li a.active:before {
  background: #000;
}

.hover .fixed .navbar ul li a.active:before {
  background: #efefef;
}

.contactoverview.contstn .contactbox.col-12 {
  border: none;
}

.contactoverview.contstn .office h4 {
  text-align: center;
  font-size: 36px;
  letter-spacing: 2px;
  line-height: 32px;
  text-transform: uppercase;
  font-family: Franklin Gothic Demi Cond;
  margin-bottom: 35px;
}

.contactoverview.contstn .officebox {
  display: flex;
  justify-content: space-between;
}

.contactoverview.contstn .officebox ul {
  width: 30%;
}

.channelhead h6 i {
  font-size: 26px;
  position: relative;
  top: 1px;
  left: -1px;
}

.exclusivehead tr {
  vertical-align: middle;
  border-bottom: solid 1px #f5f5f5;
}

.exclusivehead p {
  margin: 0;
}

.numberconts .title h3 {
  background: none;
  color: #fff;
  -webkit-text-fill-color: inherit;
}

.booknow.filter .footerform .form ul li:last-child {
  width: 50%;
  justify-content: center;
  margin: 0px auto;
}

.smartcity .title p {
  width: 80%;
}

#blacktext .fixed .headerstn {
  border: none;
}

.title h3 {
  width: 60%;
  margin: 0px auto;
}

.navbar ul li a.desktop-show {
  display: block;
}

.navbar ul li a.mobile-show {
  display: none;
}

.contentpage ul {
  margin-bottom: 25px;
}

.ftrbox ul li a:hover,
.copyrights p a:hover {
  color: #ea3549;
}

.filter .form .btn-11 {
  margin-top: 40px;
}

.mediastn {
  float: left;
  width: 100%;
}

.medleft {
  width: 22%;
}

.medright {
  width: 75%;
}

.years {
  margin-bottom: 15px;
}

.years ul li {
  margin-right: 30px;
}

.years ul li a.tab--active {
  background: #d12341;
  border-radius: 5px;
  color: #fff;
  display: flex;
  padding: 0 10px;
}

.medright .react-tabs ul {
  margin-bottom: 50px;
  text-align: center;
}

.eventsbox {
  float: left;
  margin: 0 2%;
  width: 46%;
}

.eventsbox a {
  float: left;
  margin-bottom: 25px;
  width: 100%;
}

.eventsbox h5 {
  color: #000;
  float: left;
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.medleft ul li {
  border-bottom: 1px solid #a1a1a2;
  float: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.medleft ul li a {
  color: #000;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.medleft ul li a.active,
.medright .react-tabs ul li.react-tabs__tab--selected {
  color: #cd2742;
}

.medright .react-tabs ul li {
  border-right: 1px solid #e1e1e1;
  cursor: pointer;
  float: left;
  line-height: 15px;
  position: relative;
  width: 8.3%;
}

.medleft ul {
  background: #e3e1e1;
  float: left;
  padding: 25px;
  width: 100%;
}

.medleft ul li {
  border-bottom: 1px solid #a1a1a2;
  float: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.medleft ul li:last-child,
.medright .react-tabs ul li:last-child {
  border: none;
}

.channelhead {
  position: relative;
}

.dwm_pdf {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.dwm_pdf .btn-11 {
  background-color: rgb(255 255 255 / 70%);
  padding-right: 0;
  border: none;
  border-radius: 20px;
  padding: 0 7px;
}

.dwm_pdf .btn-11:before,
.dwm_pdf .btn-11:after {
  background-color: rgb(158 158 158 / 70%);
}

.dwm_pdf .btn-11 span {
  /* font-size: 8px; */
  padding: 0 10px;
  letter-spacing: 1.2px;
  line-height: 35px;

}

.dwm_pdf .btn-11 span:hover {
  color: #fff;
}

.dwm_pdf a svg {
  width: 15px;
}

.dwn_pdf a {
  all: unset;
}

.search_data_card {
  width: 30%;
}

.search_card_img  img{
  width: 100%;
  object-position: center;
  object-fit: contain;
  text-align: center;
}

.search_card_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdfdfd;
  border: 1px solid #f6f6f6;
  gap: 0.5rem;
  padding: 0 10px;
}

.search_cnt_data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5%;
  row-gap: 50px;
}

.search_card_data_lft {
  width: 75%;
  padding: 10px 0;
}

.search_card_data_rgt {
  width: 25%;
}

.serach_logo_img {
  width: 100%;
}

.search_card_data_lft p {
  font-family: 'Poppins';
  margin-bottom: auto;
  padding: 0;
  color: rgb(0 0 0 / 50%);
  font-size: 13px;
  letter-spacing: 0.3px;
  white-space: nowrap;
  text-align: left;
  text-transform: uppercase;
}
.search_card_data_lft h4 {
  max-height: 75px;
}

.serach_channel_no {
  display: grid;
}

.serach_channel_no span {
  text-align: center;
  font-size: 13px;
}
.search_cnt .Srch_btn{
  display: none;
}
/* .loading_screen{
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

} */
.loading_screen {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;  
}
.loading_screen .loading_loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #1561a9;
  border-right: 4px solid transparent;
  animation: rotation 1s linear infinite;
  position: relative;
}

.loading_screen .loading_loader:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left: 4px solid #ee3147;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#pagination {
  margin: 0;
  padding: 0;
  text-align: center
}
#pagination li {
  display: inline
}
#pagination li {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000
}

/* Active and Hoverable Pagination */
#pagination li {
  border-radius: 5px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s
    
}
#pagination li.active {
  background-color: #ee3147;
  color: #fff
}
#pagination li:hover:not(.active) {
  background-color: #ddd;
} 
ul#pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Image_loader {
  width:  100%;
  height: 270px;
  position: relative;
  background: white;
}
.Image_loader::after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(90deg, transparent, rgba(238, 238, 238, 0.5) 50%, transparent 100%),
                    linear-gradient(#DDD 74px, transparent 0),
                    linear-gradient(#DDD 16px, transparent 0),
                    linear-gradient(#DDD 24px, transparent 0);
  background-repeat: no-repeat;
  background-size: 50px 170px, 100% 74px, 100% 16px, 100% 32px;
  background-position: 0 0, 0 0, 0px 85px, 0px 110px;
  animation: animloader126 1s linear infinite;
}

@keyframes animloader126 {
  0% {
    background-position: 0 0, 0 0, 0px 85px, 0px 110px;
  }
  100% {
    background-position: 360px 0, 0 0, 0px 85px, 0px 110px;
  }
}
.Search_nav_btn img{
  width: 40px;
  filter: brightness(100%) invert(100%);
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 0.35rem;
}
.fixed .Search_nav_btn img,
#blacktext .Search_nav_btn img{
  filter: none;
}
.Search_nav_btn {
  margin-bottom: -15px;
}




